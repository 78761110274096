import { Search } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Button,
  Card,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  deleteUserForecast,
  fetchQuestionById,
  fetchUserForecastsByQuestion,
  selectUserForecastsByQuestion
} from '../../store/slices/outcomeSlice';
import RoleBadgeIcon from '../icons/RoleBadgeIcon';
import ConfirmationModal from '../modals/ConfirmationModal';

const AdminForecastView = (questionId) => {
  const [forecasts, setForecasts] = useState(
    useSelector((state) =>
      selectUserForecastsByQuestion(state, questionId.questionId)
    )
  );
  const usersData = useSelector((state) => state.users.entities);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const [shown, setShown] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteForecastId, setDeleteForecastId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleRowExpandClick = (userId) => {
    setExpandedRows((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const getUserForecasts = (userId) => {
    return forecasts
      .filter((forecast) => forecast.created_by.id === userId)
      .sort((forecastA, forecastB) =>
        forecastB.created_at.localeCompare(forecastA.created_at)
      );
  };

  if (!forecasts) {
    setShown(false);
  }

  const deleteForecast = async () => {
    const token = localStorage.getItem('auth_token');
    let payload = {
      forecastId: deleteForecastId,
      auth_token: token
    };
    try {
      await dispatch(deleteUserForecast(payload))
        .unwrap()
        .then((response) => {
          if (response.status === 'success') {
            try {
              dispatch(fetchUserForecastsByQuestion(questionId.questionId))
                .unwrap()
                .then((response) => {
                  setForecasts(response.data);
                  // refreshes user stats and comments
                  dispatch(
                    fetchQuestionById({
                      questionId: questionId.questionId,
                      auth_token: token
                    })
                  );
                })
                .catch((err) => {
                  if (
                    err.message ===
                    'Forecasts could not be found for the given question.'
                  ) {
                    setShown(false);
                  } else {
                    setErrorMessage(err.message);
                  }
                });
            } catch (err) {
              setErrorMessage(err.message);
            }
          }
        });
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  // Get current forecasts
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const uniqueUsers = forecasts.reduce((uniqueUsers, forecast) => {
    const lowercaseQuery = searchQuery.toLowerCase();

    // Filter based on username
    if (
      !uniqueUsers.some((u) => u.created_by.id === forecast.created_by.id) &&
      forecast.created_by.username.toLowerCase().includes(lowercaseQuery)
    ) {
      uniqueUsers.push(forecast);
    }
    return uniqueUsers;
  }, []);
  const currentUsers = uniqueUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      {shown && forecasts.length !== 0 && (
        <Card className="QuestionCard rounded shadow-lg mx-10 my-5">
          <div className="flex items-center w-full p-4">
            <IconButton
              size="small"
              onClick={handleExpandClick}
              style={{ float: 'left', marginRight: '10px' }}>
              {expanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>

            <Typography sx={{ font: 'bold', fontSize: '1.1rem' }}>
              User Submitted Forecasts
            </Typography>
          </div>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {' '}
                    <Typography color="error">{errorMessage}</Typography>{' '}
                  </TableCell>
                  <TableCell sx={{ display: 'flex' }}>
                    <Typography variant="h8" sx={{ margin: 'auto 0' }}>
                      Username
                    </Typography>
                    <FormControl
                      variant="standard"
                      sx={{ paddingLeft: '15px' }}>
                      <Input
                        size="small"
                        id="input-with-icon-adornment"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        startAdornment={
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentUsers.map((forecast) => (
                  <React.Fragment key={forecast.created_by.id}>
                    <TableRow>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleRowExpandClick(forecast.created_by.id)
                          }>
                          {expandedRows.includes(forecast.created_by.id) ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowRightIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {
                          <div className="pb-1 flex">
                            <Typography
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                  `/profile/${forecast.created_by.username}`
                                );
                              }}
                              sx={{ fontSize: '0.875rem' }}
                              className="hover:underline hover:cursor-pointer"
                              color="primary.main">
                              {forecast.created_by.username}
                            </Typography>
                            <RoleBadgeIcon
                              role={usersData[forecast.created_by.id]?.role}
                            />
                          </div>
                        }
                      </TableCell>
                      <TableCell colSpan={3}>
                        {/* Placeholder for colspan */}{' '}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={5}>
                        <Collapse
                          in={expandedRows.includes(forecast.created_by.id)}
                          timeout="auto"
                          unmountOnExit>
                          <Box sx={{ margin: 1 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div">
                              Forecasts
                            </Typography>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Probability</TableCell>
                                  <TableCell>Created</TableCell>
                                  <TableCell>Status</TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {getUserForecasts(forecast.created_by.id).map(
                                  (f) => (
                                    <TableRow key={f.id}>
                                      <TableCell>
                                        {(
                                          f.probability.display_probability *
                                          100
                                        )
                                          .toFixed(1)
                                          .replace('.0', '') + '%'}
                                      </TableCell>
                                      <TableCell>
                                        {moment
                                          .utc(
                                            f.created_at,
                                            'YYYY-MM-DDThh:mm:ss'
                                          )
                                          .local()
                                          .fromNow()}
                                      </TableCell>
                                      <TableCell
                                        sx={{ textTransform: 'capitalize' }}>
                                        {f.state}
                                      </TableCell>
                                      <TableCell>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() => {
                                            setDeleteForecastId(f.id);
                                            setShowDeleteConfirm(true);
                                          }}>
                                          Delete
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            <div>
              {/* Pagination */}
              {Array.from({
                length: Math.ceil(uniqueUsers.length / usersPerPage)
              }).map((_, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  color="primary"
                  onClick={() => paginate(index + 1)}>
                  {index + 1}
                </Button>
              ))}
            </div>
          </Collapse>
          {showDeleteConfirm && (
            <ConfirmationModal
              shown={showDeleteConfirm}
              close={() => {
                setShowDeleteConfirm(false);
              }}
              confirm={() => {
                setShowDeleteConfirm(false);
                deleteForecast();
              }}
              confirmationMessage="Do you really want to delete this user forecast? This process cannot be undone."
            />
          )}
        </Card>
      )}
    </div>
  );
};

export default AdminForecastView;
