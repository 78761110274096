import { useTheme } from '@mui/material';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

export default function ForecastTimeline({
  axisLabels,
  width,
  title,
  falseData,
  forecasts
}) {
  const theme = useTheme();

  function compareCrowdForecasts(a, b) {
    if (moment(a.created_at) < moment(b.created_at)) {
      return -1;
    }
    if (moment(a.created_at) > moment(b.created_at)) {
      return 1;
    }
    return 0;
  }

  // returns the length of time that has passed between the first crowd forecast and the most recent, represented in days
  const time_crowd_forecasting = () => {
    if (forecasts) {
      const sorted = [...forecasts].sort(compareCrowdForecasts);
      if (sorted.length > 0) {
        return moment.duration(moment(sorted[sorted.length - 1].created_at).diff(moment(sorted[0].created_at))).asDays()
      } else {
        return 0
      }
    } else return 0
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: title !== undefined ? true : false,
        text: title !== undefined ? title : '',
        color: theme.palette.text.secondary,
        font: {
          size: 20
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = `True: ${context.parsed.y.toFixed(0)}%`;
            return label;
          },
          title: function (context) {
            let title = moment.utc(context[0].parsed.x).local().format(
              'dddd, MMMM Do YYYY, h:mm:ss a'
            );
            return title;
          }
        }
      }
    },
    scales: {
      y: {
        min: 0,
        max: 100,
        title: {
          display: axisLabels,
          text: 'Probability',
          color: theme.palette.text.secondary,
          font: {
            size: 20
          }
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value + '%';
          },
          color: theme.palette.text.primary
        },
        grid: {
          display: true,
          color: theme.palette.background.card
        }
      },
      x: {
        type: 'time',
        time: {
          unit: time_crowd_forecasting() > 2 ? "day" : "hour",
        },
        title: {
          display: false
        },
        grid: {
          display: true,
          color: theme.palette.background.card
        },
        ticks: {
          color: theme.palette.text.primary,
          autoSkip: true,
          source: 'auto',
          maxTicksLimit: width === 'large' ? 10 : 5
        }
      }
    }
  };
  var data = {}
  if (!falseData) {
    data = {
      labels: forecasts.map((f) =>
        moment.utc(f.created_at).local()
      ),
      datasets: [
        {
          label: 'Dataset 1',
          data: forecasts.map((f) => f.probability * 100),
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main
        }
      ]
    };
  } else {
    data = falseData
  }
  var dimensions = {}
  switch (width) {
    case 'large':
      dimensions = { width: '38rem', height: '16rem' }
      break;
    case 'small':
      dimensions = { width: '16rem', height: '5rem' }
      break;
    case 'card':
      dimensions = { width: '100%', height: '10vw' }
      break;
    default:
      dimensions = { width: '100%', height: '10vw' }
  }
  return (
    <div style={dimensions}>
      <Line options={options} data={data} />
    </div>
  );
}
