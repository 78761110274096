import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { AuthContext } from '../../App';
import FrugalForecastCard from '../../components/cards/FrugalForecastCard';
import QuestionCard from '../../components/cards/QuestionCard';
import CommentList from '../../components/lists/CommentList';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import {
  fetchQuestionById,
  fetchUserForecastsByQuestion,
  selectOutcomeById,
  selectQuestionById
} from '../../store/slices/outcomeSlice';

export default function QuestionView() {
  const dispatch = useDispatch();
  const { isLoggedIn, userData } = useContext(AuthContext);
  const [isVotingDisabled, setIsVotingDisabled] = useState(false);
  let { questionId } = useParams();
  const questionStatus = useSelector(
    (state) => state.outcomes.questions.status
  );
  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );

  const outcome = useSelector((state) =>
    selectOutcomeById(state, question?.outcome_id)
  );

  useEffect(() => {
    dispatch(fetchUserForecastsByQuestion(questionId));
  }, [dispatch, questionId]);

  useEffect(() => {
    if (questionStatus === 'idle' && question === undefined) {
      const token = localStorage.getItem('auth_token');
      dispatch(
        fetchQuestionById({ questionId: questionId, auth_token: token })
      );
    }
  }, [questionStatus, question, questionId, dispatch]);

  let content;
  if (questionStatus === 'idle' && question === undefined) {
    content = (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  } else if (question) {
    content = (
      <div>
        <QuestionCard
          question={question}
          isLoggedIn={isLoggedIn}
          isVotingDisabled={isVotingDisabled}
          setIsVotingDisabled={(val) => setIsVotingDisabled(val)}
          detail={true}
        />
        {question.status === 'Accepted' &&
          outcome?.forecast_mechanism === 'derived' &&
          (userData.role === 'Questioner' ||
            (outcome.created_by.id === userData.id && outcome.owner_can_forecast)) && (
            <div className="flex justify-center">
              <FrugalForecastCard questionId={questionId} />
            </div>
          )}
        <CommentList question={question} maxIndentLevels={4} />
      </div>
    );
  } else {
    content = <p className="text-sm text-center">Question could be found.</p>;
  }

  return (
    <div className="QuestionView mt-5">
      <div className="flex ml-10 mt-7 items-center">
        <HierarchicalBreadcrumbs
          outcomes={true}
          outcome={outcome ? outcome.title : 'Loading...'}
          outcomeId={question ? question.outcome_id : '?'}
          question={true}
        />
      </div>

      <section className="question-view">{content}</section>
    </div>
  );
}
