import { Button, Card, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  addConfigurationsToPreset,
  addNewBucketPreset,
  fetchBucketConfigurations,
  removeConfigurationsFromPreset,
  selectAllConfigurations,
  selectPresetById,
  updateBucketPreset
} from '../../store/slices/bucketSlice';
import MultiSelectDropdown from '../other/MultiSelectDropdown';
import './styles.css';

export default function BucketPresetForm({
  shown,
  close,
  isEditMode = false,
  id
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const preset = useSelector((state) => selectPresetById(state, id));
  const configurations = useSelector(selectAllConfigurations);
  const configurationStatus = useSelector(
    (state) => state.buckets.configurations.status
  );
  const [presetName, setPresetName] = useState('');
  const [selectedConfigurations, setSelectedConfigurations] = useState(
    preset?.configurations?.map((config) => config.name)
  );
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (configurationStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchBucketConfigurations(token));
    }
  }, [configurationStatus, dispatch]);

  useEffect(() => {
    if (isEditMode && preset) {
      setPresetName(preset.name);
      setSelectedConfigurations(
        preset.configurations.map((config) => config.name)
      );
    } else {
      setPresetName('');
      setSelectedConfigurations([]);
    }
  }, [isEditMode, preset, id]);

  const submit = async () => {
    if (!presetName) {
      setErrorMessage('Please enter a name for the preset');
      return;
    }

    if (!(await checkTokenStatus())) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    }

    const token = localStorage.getItem('auth_token');
    const payload = {
      name: presetName,
      auth_token: token
    };

    const addedConfigurationIds = selectedConfigurations
      .filter(
        (selectedConfig) =>
          !preset?.configurations.some(
            (config) => config.name === selectedConfig
          )
      )
      .map((selectedConfig) => {
        const matchingConfig = configurations.find(
          (config) => config.name === selectedConfig
        );
        return matchingConfig ? matchingConfig.id : null;
      })
      .filter((id) => id !== null);

    const removedConfigurationIds = preset?.configurations
      .filter(
        (config) =>
          !selectedConfigurations.some(
            (selectedConfig) => selectedConfig === config.name
          )
      )
      .map((config) => {
        const matchingConfig = configurations.find(
          (c) => c.name === config.name
        );
        return matchingConfig ? matchingConfig.id : null;
      })
      .filter((id) => id !== null);

    try {
      if (isEditMode) {
        payload.id = id;
        await dispatch(updateBucketPreset(payload))
          .unwrap()
          .then((data) => {
            if (data.status === 'success') {
              if (addedConfigurationIds.length > 0) {
                addConfigurations({
                  id: data.data.id,
                  configurations: addedConfigurationIds,
                  auth_token: token
                });
              } else if (removedConfigurationIds.length > 0) {
                removeConfigurations({
                  id: data.data.id,
                  configurations: removedConfigurationIds,
                  auth_token: token
                });
              } else {
                setErrorMessage('');
                close();
              }
            } else {
              setErrorMessage(
                data.message || 'An error occurred while updating'
              );
            }
          });
      } else {
        await dispatch(addNewBucketPreset(payload))
          .unwrap()
          .then((data) => {
            if (data.status === 'success') {
              if (addedConfigurationIds.length > 0)
                dispatch(
                  addConfigurationsToPreset({
                    id: data.data.id,
                    configurations: addedConfigurationIds,
                    auth_token: token
                  })
                );
              setErrorMessage('');
              setPresetName('');
              close();
            } else {
              setErrorMessage(
                data.message || 'An error occurred while creating'
              );
            }
          });
      }
    } catch (err) {
      setErrorMessage(`Operation failed: ${err.message}`);
    }
  };

  const addConfigurations = async (payload) => {
    try {
      await dispatch(addConfigurationsToPreset(payload))
        .unwrap()
        .then((data) => {
          if (data.status === 'success') {
            setErrorMessage('');
            close();
          } else {
            setErrorMessage(data.message || 'An error occurred while creating');
          }
        });
    } catch (err) {
      setErrorMessage(err.message || 'An error occurred while creating');
    }
  };

  const removeConfigurations = async (payload) => {
    try {
      await dispatch(removeConfigurationsFromPreset(payload))
        .unwrap()
        .then((data) => {
          if (data.status === 'success') {
            setErrorMessage('');
            close();
          } else {
            setErrorMessage(data.message || 'An error occurred while creating');
          }
        });
    } catch (err) {
      setErrorMessage(err.message || 'An error occurred while creating');
    }
  };

  return shown ? (
    <div className="b-1 modal-backdrop" onClick={close}>
      <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div>
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '1rem', my: 1 }}
            className="text-center">
            {isEditMode ? 'Edit Bucket Preset' : 'Create A New Bucket Preset'}
          </Typography>
          <Typography color="error">{errorMessage}</Typography>
          <TextField
            variant="outlined"
            multiline
            placeholder="Preset Name"
            value={presetName}
            onChange={(event) => setPresetName(event.target.value)}
            minRows="1"
            sx={{ my: 1 }}
            className="w-full"></TextField>
          <MultiSelectDropdown
            label={'Set configurations(s)'}
            options={configurations?.map((config) => config.name)}
            selectedValues={selectedConfigurations}
            onSelectedValuesChange={setSelectedConfigurations}
          />
          <div className="flex pt-2 my-1">
            <div className="w-full mx-2">
              <Button variant="contained" className="w-full" onClick={submit}>
                {isEditMode ? 'Update' : 'Create'}
              </Button>
            </div>
            <div className="w-full mx-2">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  ':hover': { backgroundColor: '#757575' }
                }}
                onClick={close}
                className="w-full">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  ) : null;
}
