import React from "react";
import { Typography, useTheme } from "@mui/material";
import NavigationGrid from "../../components/other/NavigationGrid";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import GavelIcon from '@mui/icons-material/Gavel';
import SearchIcon from '@mui/icons-material/Search';
import HierarchicalBreadcrumbs from "../../components/other/HierarchicalBreadcrumbs";

export default function ModeratorNavigationView() {
  const isDarkMode = useTheme().palette.mode === 'dark'
  const navigationItems = [
    {
        icon: {
            component: <GavelIcon style={{ color: "white"}} fontSize="large" sx={{scale:"1.25"}} />,
            color: isDarkMode ? "bg-gradient-to-br from-cyan-600 to-blue-800" : "bg-gradient-to-br from-blue-400 to-cyan-600",
            },
        title: "Moderate Questions",
        description: "Moderate questions on the platform.",
        link: "/moderate",
    },
    {
      icon: {
        component: <InsertChartIcon style={{ color: "white"}} fontSize="large" sx={{scale:"1.25"}} />,
        color: isDarkMode ? "bg-gradient-to-br from-lime-600 to-green-800" : "bg-gradient-to-br from-lime-400 to-green-600" 
      },
      title: "User Performance",
      description: "Explore user performance.",
      link: "/leaderboard",
    },
    {
      icon: {
        component: <SearchIcon style={{ color: "white"}} fontSize="large" sx={{scale:"1.25"}} />,
        color: isDarkMode ? "bg-gradient-to-br from-rose-600 to-red-800" : "bg-gradient-to-br from-rose-400 to-red-600"
      },
      title: "User Lookup",
      description: "Lookup users on the platform.",
      link: "/users",
    }
  ];

  return (
    <div className="ModeratorNavigationView">
      <div className="flex justify-between ml-5 mt-7 items-center">
        <HierarchicalBreadcrumbs quickActions={true} />
      </div>
      <div className="flex flex-col relative shrink-0 box-border items-center pt-5 pb-12 px-5">
        <div className="box-border mt-3 mx-auto">
          <Typography variant="h4" sx={{mb:2}}>
            <strong>Quick Actions</strong>
          </Typography>
        </div>
        <NavigationGrid navigationItems={navigationItems} />
      </div>
    </div>
  );
}
