import { Button, Card, Checkbox, Divider, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import {
  selectOutcomesFilters,
  toggleOutcomeFilters
} from '../../store/slices/outcomeSlice';
import { selectAllUsers } from '../../store/slices/userSlice';
import OutcomeStatusFilter from '../other/OutcomeStatusFilter';

export default function FilterModal({ shown, close }) {
  const closeModal = () => {
    close();
  };
  const outcomeFilters = useSelector((state) => selectOutcomesFilters(state));
  const users = useSelector((state) => selectAllUsers(state));

  const dispatch = useDispatch();

  const handleFilterChecked = (event) => {
    const filter = event.target.value;
    if (filter === 'own') {
      dispatch(toggleOutcomeFilters({ toggleUnseen: true }));
    } else if (filter === 'user') {
      dispatch(toggleOutcomeFilters({ toggleUserEnabled: true }));
    } else {
      throw new Error('Incorrect parameter.');
    }
  };

  const handleOnSelect = (item) => {
    dispatch(toggleOutcomeFilters({ userUsername: item.username }));
  };

  const handleOnSearch = (string, results) => {
    if (string === '') {
      dispatch(toggleOutcomeFilters({ userUsername: '' }));
    }
  };

  const handleOnClear = () => {
    dispatch(toggleOutcomeFilters({ userUsername: '' }));
  };

  return shown ? (
    <div className="modal-backdrop" onClick={close}>
      <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <Typography
            sx={{ fontWeight: 700, fontSize: '1.5rem' }}
            className="text-center">
          Filter
        </Typography>
        <Divider sx={{ mt: 1, mb: 0.5}}>
          Outcome Status
        </Divider>
        <OutcomeStatusFilter />
        <Divider sx={{ mt: 1}}>
          My Participation
        </Divider>
        <div className="items-center flex mt-1 mb-1">
          <Checkbox
            sx={{ mr: 1, p: 0 }}
            name="filterCheckboxes"
            id="ownCheckbox"
            checked={outcomeFilters.own}
            onChange={handleFilterChecked}
            value="own"
          />
          <Typography sx={{ mr: 1 }}>Unseen</Typography>
        </div>
        <Divider sx={{ mt: 1}}>
          Other
        </Divider>
        <div className="items-center flex mt-2">
          <Checkbox
            sx={{ mr: 1, p: 0 }}
            name="filterCheckboxes"
            id="userCheckbox"
            checked={outcomeFilters.user.enabled}
            onChange={handleFilterChecked}
            value="user"
          />
          <Typography sx={{ mr: 1 }}>Author Lookup:</Typography>
          <div className="w-2/5">
            <ReactSearchAutocomplete
              items={users}
              fuseOptions={{ keys: ['username'] }}
              resultStringKeyName="username"
              onSelect={handleOnSelect}
              onSearch={handleOnSearch}
              onClear={handleOnClear}
              styling={{ zIndex: 4 }}
              autoFocus
            />
          </div>
        </div>
        <div className="m-2 flex justify-end">
          <Button variant="contained" onClick={closeModal} className="">
            Close
          </Button>
        </div>
      </Card>
    </div>
  ) : null;
}
