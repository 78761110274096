import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import React from 'react';

export default function ForecastCard({ forecast }) {
  return (
    <TableRow>
      <TableCell>
        {moment.utc(forecast.forecast_date).local().format('dddd MMMM Do YYYY')}
      </TableCell>
      <TableCell>{(forecast.probability * 100).toFixed(2)} %</TableCell>
    </TableRow>
  );
}
