import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import './App.css';
import {
  fetchSettingGroups,
  fetchSettings
} from './store/slices/settingsSlice';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import TimeOutModal from './components/modals/TimeOutModal';
import Footer from './components/other/Footer';
import Navbar from './components/other/Navbar';
import { getColourPalette } from './components/other/Theme';
import AboutView from './pages/AboutView';
import FrequentlyAskedQuestionsView from './pages/FrequentlyAskedQuestionView.jsx';
import LandingPage from './pages/LandingPage.jsx';
import LoginView from './pages/LoginView';
import PageNotFound from './pages/PageNotFound';
import PasswordResetTokenView from './pages/PasswordResetTokenView.jsx';
import PasswordResetView from './pages/PasswordResetView.jsx';
import AccountSettings from './pages/protected/AccountSettings';
import AdminSettingsView from './pages/protected/AdminSettingsView';
import CommentView from './pages/protected/CommentView';
import DemographicsQuestionnaireView from './pages/protected/DemographicsQuestionnaireView';
import LeaderboardView from './pages/protected/LeaderboardView';
import OpenMindedQuestionnaireView from './pages/protected/OpenMindedQuestionnaireView';
import OutcomeOwnerNavigationView from './pages/protected/OutcomeOwnerNavigationView.jsx';
import ModeratorNavigationView from './pages/protected/ModeratorNavigationView.jsx';
import ModerateQuestionView from './pages/protected/ModerateQuestionsView.jsx';
import OutcomesView from './pages/protected/OutcomesView';
import OutcomeView from './pages/protected/OutcomeView';
import PersonalityQuestionnaireView from './pages/protected/PersonalityQuestionnaireView';
import ProfileView from './pages/protected/ProfileView';
import QuestionerNavigationView from './pages/protected/QuestionerNavigationView';
import QuestionForecasting from './pages/protected/QuestionForecasting';
import QuestionView from './pages/protected/QuestionView';
import SubmitView from './pages/protected/SubmitView';
import UserGroupsView from './pages/protected/UserGroupsView';
import UsersView from './pages/protected/UsersView';
import RegisterView from './pages/RegisterView';
import VerifyView from './pages/VerifyView';
import { fetchUser } from './store/slices/userSlice';
import { TourProvider } from '@reactour/tour';

export const AuthContext = createContext();

const steps = [
  {
    selector: '#question-text-event',
    content:
      'Write about an event for the main subject of the question. For example, with the forecasting question: "Will 30% of deforestation of the rainforest occur before 1st January 2030, as reported by BBC News, on or before 2nd January 2030?", the event is "30% of deforestation of the rainforest".'
  },
  // {
  //   selector: '#question-text-state',
  //   content:
  //     'Optionally enter a state, which is a verb reflecting the completion state of the event, for example "occur".'
  // },
  {
    selector: '#open-conditional',
    content:
      'Click here to expand one or more conditions to your question, which are additional events that your question is dependant on. For example, with the forecasting question: "Will 30% of deforestation of the rainforest occur, if Brazil reach net zero deforestation, before 1st January 2030, as reported by BBC News, on or before 2nd January 2030?", the condition is "if Brazil reach net zero deforestation".'
  },
  {
    selector: '#question-text-date-preposition',
    content: 'Preposition for the date for example "before".'
  },
  {
    selector: '#date-picker',
    content:
      'Enter the date the question will resolve by. This is the date by which the event will have occured and not the date on which it will have been reported by.'
  },
  {
    selector: '#autocomplete-source',
    content:
      'Pick the trusted authority source that will report when the question subject resolves. This should be a source capable of unambiguously verifying true or false resolution of the evet. Alternatively, enter a custom resolution source.'
  },
  {
    selector: '#link-authority',
    content: 'Optionally enter a link to the source authority.'
  },
  {
    selector: '#question-text-source-date-preposition',
    content: 'Preposition for the date for example "before".'
  },
  {
    selector: '#source-date-picker',
    content:
      "Enter the date by which the event's resolution will be reported on."
  },
  {
    selector: '#question-text-relevance-type',
    content:
      "Specify the influence of the question on the outcome. I.e. More likely will imply that the question's truthful resolution will make the Outcome statement most likely"
  },
  {
    selector: '#open-extra-info',
    content: 'Add any extra information that needs to be included.'
  },
  {
    selector: '#submit-question-button',
    content: 'Review and then submit your question.'
  }
];

const userRouteAccess = {
  admin: [
    'outcomes',
    'outcome',
    'comment',
    'question',
    'submit',
    'forecasting',
    'account-settings',
    'profile',
    'users',
    'leaderboard',
    'admin-settings',
    'personalityQuestionnaire',
    'demographicsQuestionnaire',
    'openMindedQuestionnaire',
    'user-groups',
    'outcome-owner-navigation',
    'moderator-navigation',
    'moderate'
  ],
  moderator: [
    'outcomes',
    'outcome',
    'comment',
    'question',
    'submit',
    'forecasting',
    'account-settings',
    'profile',
    'users',
    'leaderboard',
    'personalityQuestionnaire',
    'demographicsQuestionnaire',
    'openMindedQuestionnaire',
    'user-groups',
    'outcome-owner-navigation',
    'moderator-navigation',
    'moderate'
  ],
  outcomeOwner: [
    'outcomes',
    'outcome',
    'comment',
    'question',
    'submit',
    'account-settings',
    'profile',
    'users',
    'leaderboard',
    'personalityQuestionnaire',
    'demographicsQuestionnaire',
    'openMindedQuestionnaire',
    'user-groups',
    'outcome-owner-navigation'
  ],
  questioner: [
    'outcomes',
    'outcome',
    'comment',
    'question',
    'account-settings',
    'profile',
    'users',
    'leaderboard',
    'personalityQuestionnaire',
    'demographicsQuestionnaire',
    'openMindedQuestionnaire',
    'user-groups',
    'questioner-navigation',
    'outcome'
  ],
  observer: [
    'outcomes',
    'outcome',
    'comment',
    'question',
    'account-settings',
    'profile',
    'users',
    'leaderboard',
    'user-groups'
  ]
};

export const ColorModeContext = React.createContext({
  toggleColorMode: () => { }
});

export const checkTokenStatus = () => {
  const token = localStorage.getItem('auth_token');
  if (token === null || token === undefined) {
    return false;
  }
  const tokenBody = JSON.parse(atob(token.split('.')[1]));
  const tokenExp = tokenBody.exp;
  const now = new Date().getTime() / 1000;
  if (now > tokenExp) {
    return false;
  }
  return true;
};

export function App() {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});
  const [mode, setMode] = useState('light');
  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      }
    }),
    []
  );
  let theme = useMemo(() => createTheme(getColourPalette(mode)), [mode]);
  theme = responsiveFontSizes(theme);

  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    async function getUserData() {
      if (checkTokenStatus() === false) {
        localStorage.removeItem('auth_token');
        setIsLoggedIn(false);
        setUserData({});
        return <Navigate to="/login" />;
      } else {
        await dispatch(fetchUser(token))
          .unwrap()
          .then((data) => {
            if (data.status === 'success') {
              setUserData(data.data);
              setIsLoggedIn(true);
            } else {
              localStorage.removeItem('auth_token');
              setIsLoggedIn(false);
              setUserData({});
              return <Navigate to="/login" />;
            }
          })
          .catch((error) => {
            console.error('Failed to fetch user:', error);
          });
      }
    }

    getUserData();
  }, [isLoggedIn, setIsLoggedIn, dispatch]);

  useEffect(() => {
    if (localStorage.getItem('mode')) {
      setMode(localStorage.getItem('mode'));
    }
  }, []);

  return (
    <TourProvider
      steps={steps}
      styles={{
        popover: (base) => ({
          ...base,
          '--reactour-accent': theme.palette.primary.main,
          borderRadius: '5px',
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary
        }),
        controls: (base) => ({ ...base }),
      }}
      nextButton={({
        currentStep,
        stepsLength,
        setCurrentStep,
      }) => {
        const last = currentStep === stepsLength - 1
        return (
          <div
            className=" hover:cursor-pointer"
            onClick={() => {
              if (!last) {
                setCurrentStep((s) => (s + 1))
              }
            }}
          >
            <ArrowForwardIcon color={last ? 'disabled' : 'inherit'} />
          </div>
        )
      }}
      prevButton={({
        currentStep,
        setCurrentStep,
      }) => {
        const first = currentStep === 0
        return (
          <div
            className=" hover:cursor-pointer"
            onClick={() => {
              if (!first) {
                setCurrentStep((s) => s - 1)
              }
            }}
          >
            <ArrowBackIcon color={first ? 'disabled' : 'inherit'} />
          </div>
        )
      }}
    >
      <div className="flex flex-col min-h-screen justify-between">
        <AuthContext.Provider
          value={{ isLoggedIn, setIsLoggedIn, userData, setUserData }}
        >
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <div>
                <Navbar
                  register={
                    process.env.REACT_APP_REGISTER_TYPE === 'verify' ||
                    process.env.REACT_APP_REGISTER_TYPE === 'open'
                  }
                />
                <Routes>
                  <Route path="/" element={<LandingPage />} />{' '}
                  {/* New default landing page */}
                  <Route path="/login" element={<LoginView />} />
                  {process.env.REACT_APP_REGISTER_TYPE === 'open' ? (
                    <>
                      <Route
                        path="/register"
                        element={<RegisterView verify={false} />}
                      />
                    </>
                  ) : (
                    process.env.REACT_APP_REGISTER_TYPE === 'verify' && (
                      <>
                        <Route
                          path="/register"
                          element={<RegisterView verify={true} />}
                        />
                        <Route path="/verify/:token" element={<VerifyView />} />
                      </>
                    )
                  )}
                  <Route
                    exact
                    path="/reset-password"
                    element={<ProtectedRoute routeName={'reset-password'} />}
                  >
                    <Route
                      path="/reset-password"
                      element={<PasswordResetView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/reset-password/verify/:token"
                    element={<ProtectedRoute routeName={'reset-password'} />}
                  >
                    <Route
                      path="/reset-password/verify/:token"
                      element={<PasswordResetTokenView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/outcomes"
                    element={<ProtectedRoute routeName={'outcomes'} />}
                  >
                    <Route path="/outcomes" element={<OutcomesView />} />
                  </Route>
                  <Route
                    exact
                    path="/outcome/:outcomeId"
                    element={<ProtectedRoute routeName={'outcome'} />}
                  >
                    <Route
                      path="/outcome/:outcomeId"
                      element={<OutcomeView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/outcomes/:filter"
                    element={<ProtectedRoute routeName={'outcomes'} />}
                  >
                    <Route
                      path="/outcomes/:filter"
                      element={<OutcomesView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/questions/:questionId"
                    element={<ProtectedRoute routeName={'question'} />}
                  >
                    <Route
                      path="/questions/:questionId"
                      element={<QuestionView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/questions/:questionId/comment/:commentId"
                    element={<ProtectedRoute routeName={'comment'} />}
                  >
                    <Route
                      path="/questions/:questionId/comment/:commentId"
                      element={<CommentView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/submit"
                    element={<ProtectedRoute routeName={'submit'} />}
                  >
                    <Route path="/submit" element={<SubmitView />} />
                  </Route>
                  <Route
                    exact
                    path="/forecasting"
                    element={<ProtectedRoute routeName={'forecasting'} />}
                  >
                    <Route
                      path="/forecasting"
                      element={<QuestionForecasting />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/admin-settings"
                    element={<ProtectedRoute routeName={'admin-settings'} />}
                  >
                    <Route
                      exact
                      path="/admin-settings"
                      element={<AdminSettingsView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/users"
                    element={<ProtectedRoute routeName={'users'} />}
                  >
                    <Route exact path="/users" element={<UsersView />} />
                  </Route>
                  <Route
                    exact
                    path="/user-groups"
                    element={<ProtectedRoute routeName={'user-groups'} />}
                  >
                    <Route
                      exact
                      path="/user-groups"
                      element={<UserGroupsView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/user-groups/:groupId"
                    element={<ProtectedRoute routeName={'user-groups'} />}
                  >
                    <Route
                      path="/user-groups/:groupId"
                      element={<UserGroupsView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/leaderboard"
                    element={<ProtectedRoute routeName={'leaderboard'} />}
                  >
                    <Route
                      exact
                      path="/leaderboard"
                      element={<LeaderboardView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/account-settings"
                    element={<ProtectedRoute routeName={'account-settings'} />}
                  >
                    <Route
                      exact
                      path="/account-settings"
                      element={<AccountSettings />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/profile/:username"
                    element={<ProtectedRoute routeName={'profile'} />}
                  >
                    <Route
                      exact
                      path="/profile/:username"
                      element={<ProfileView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/questionnaire/personality"
                    element={
                      <ProtectedRoute routeName={'personalityQuestionnaire'} />
                    }
                  >
                    <Route
                      exact
                      path="/questionnaire/personality"
                      element={<PersonalityQuestionnaireView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/questionnaire/demographics"
                    element={
                      <ProtectedRoute routeName={'demographicsQuestionnaire'} />
                    }
                  >
                    <Route
                      exact
                      path="/questionnaire/demographics"
                      element={<DemographicsQuestionnaireView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/questionnaire/open-minded"
                    element={
                      <ProtectedRoute routeName={'openMindedQuestionnaire'} />
                    }
                  >
                    <Route
                      exact
                      path="/questionnaire/open-minded"
                      element={<OpenMindedQuestionnaireView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/outcome-owner-navigation"
                    element={
                      <ProtectedRoute routeName={'outcome-owner-navigation'} />
                    }
                  >
                    <Route
                      exact
                      path="/outcome-owner-navigation"
                      element={<OutcomeOwnerNavigationView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/questioner-navigation"
                    element={
                      <ProtectedRoute routeName={'questioner-navigation'} />
                    }
                  >
                    <Route
                      exact
                      path="/questioner-navigation"
                      element={<QuestionerNavigationView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/moderator-navigation"
                    element={
                      <ProtectedRoute routeName={'moderator-navigation'} />
                    }
                  >
                    <Route
                      exact
                      path="/moderator-navigation"
                      element={<ModeratorNavigationView />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/moderate"
                    element={<ProtectedRoute routeName={'moderate'} />}
                  >
                    <Route exact path="/moderate" element={<ModerateQuestionView />} />
                  </Route>
                  <Route path="/about" element={<AboutView />} />
                  <Route path="/landing" element={<LandingPage />} />
                  <Route
                    path="/faq"
                    element={<FrequentlyAskedQuestionsView />}
                  />
                  <Route path="/*" element={<PageNotFound />} />
                </Routes>
              </div>
              <div>
                <TimeOutModal />
              </div>
              <div>
                <Footer className="mt-auto" />
              </div>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </AuthContext.Provider>
      </div>
    </TourProvider>
  );
}

const ProtectedRoute = ({ routeName }) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('auth_token');
  const settingsStatus = useSelector((state) => state.settings.status);
  const settingGroupsStatus = useSelector(
    (state) => state.settings.settingGroups.status
  );

  useEffect(() => {
    if (settingsStatus === 'idle') {
      dispatch(fetchSettings());
    }
  }, [settingsStatus, dispatch]);

  useEffect(() => {
    if (settingGroupsStatus === 'idle') {
      dispatch(fetchSettingGroups());
    }
  }, [settingGroupsStatus, dispatch]);

  if (checkTokenStatus()) {
    const userRole = JSON.parse(atob(token.split('.')[1])).role;
    if (
      (userRole === 'Admin' && userRouteAccess.admin.includes(routeName)) ||
      (userRole === 'Moderator' &&
        userRouteAccess.moderator.includes(routeName)) ||
      (userRole === 'Questioner' &&
        userRouteAccess.questioner.includes(routeName)) ||
      (userRole === 'OutcomeOwner' &&
        userRouteAccess.outcomeOwner.includes(routeName)) ||
      (userRole === 'Observer' && userRouteAccess.observer.includes(routeName))
    ) {
      return <Outlet />;
    } else {
      return <PageNotFound />;
    }
  } else {
    return window.location.pathname.includes('reset-password') ? (
      <Outlet />
    ) : (
      <Navigate to="/login" />
    );
  }
};
