import {
  CalendarMonthOutlined,
  EventOutlined,
  MailOutline,
  QuestionMarkOutlined
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectOutcomeById } from '../../store/slices/outcomeSlice';

export default function OutcomeStatusText({ status, outcome_id, micro }) {
  const outcome = useSelector((state) => selectOutcomeById(state, outcome_id));

  const outcomeStatusText = () => {
    if (outcome) {
      var text = '';
      if (status === 'Generation') {
        text = 'Question generation';
      } else if (status === 'Evaluation') {
        text = 'Evaluation';
      } else if (status === 'Forecasting') {
        text = 'Forecasting';
      } else {
        return 'Closed';
      }
      if (micro) {
        return text + ' started';
      } else {
        return text;
      }
    } else {
      return '';
    }
  };

  const timeBetweenStatusChange = () => {
    if (outcome) {
      if (status === 'Closed') {
        return moment.utc(outcome.end_at, 'YYYY-MM-DD').local().fromNow();
      } else if (status === 'Generation') {
        return moment
          .utc(outcome.created_at, 'YYYY-MM-DDThh:mm:ss')
          .local()
          .fromNow();
      } else if (status === 'Evaluation') {
        return moment
          .utc(outcome.generation_end_date, 'YYYY-MM-DD')
          .local()
          .fromNow();
      } else {
        return moment
          .utc(outcome.forecasting_start_date, 'YYYY-MM-DD')
          .local()
          .fromNow();
      }
    } else {
      return '';
    }
  };

  const timeTillFinish = () => {
    if (outcome) {
      if (status === 'Generation') {
        return (
          ' ends ' +
          moment
            .utc(outcome.generation_end_date, 'YYYY-MM-DD')
            .local()
            .fromNow()
        );
      } else if (status === 'Evaluation') {
        return (
          ' ends ' +
          moment
            .utc(outcome.forecasting_start_date, 'YYYY-MM-DD')
            .local()
            .fromNow()
        );
      } else if (status === 'Forecasting') {
        return (
          ' ends ' + moment.utc(outcome.end_at, 'YYYY-MM-DD').local().fromNow()
        );
      } else if (status === 'Closed') {
        return (
          ' on ' +
          moment.utc(outcome.end_at, 'YYYY-MM-DD').local().format('MMM Do YYYY')
        );
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  return micro ? (
    <Typography
      sx={{
        mr: 2,
        fontSize: '0.85rem',
        p: 0,
        display: 'flex',
        alignItems: 'center'
      }}
      color="text.secondary">
      <CalendarMonthOutlined fontSize="small" sx={{ mr: 0.3, mb: 0.3 }} />
      {outcomeStatusText()} {timeBetweenStatusChange()}. <br /> Ending in{' '}
      {timeTillFinish()}.
    </Typography>
  ) : (
    <Typography sx={{ mx: 0.6, fontSize: '0.95rem' }} color="text.secondary">
      {outcomeStatusText()}
      {timeTillFinish()}
    </Typography>
  );
}
