import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Card, Typography, Button, Select, FormControl, InputLabel, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Checkbox } from '@mui/material';
import { Sync, Gavel, OpenInNew, CompareArrows } from '@mui/icons-material';
import {
    fetchOutcomes,
    fetchQuestionsByStatus,
    acceptQuestion,
} from '../../store/slices/outcomeSlice';
import { selectFilteredQuestionsByStatus } from '../../store/slices/outcomeSlice';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import RejectQuestionModal from '../../components/modals/RejectQuestionModal';

export default function ModerateQuestionView() {
    const dispatch = useDispatch();
    const questionStatus = useSelector((state) => state.outcomes.questions.status);
    const outcomes = useSelector((state) => state.outcomes.outcomes.entities);
    const questions = useSelector((state) => selectFilteredQuestionsByStatus(state, 'pending'));
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [outcomeList, setOutcomeList] = useState(['All']);
    const [selectedOutcome, setSelectedOutcome] = useState('All');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('resolution_impact');
    const [openRejectModal, setOpenRejectModal] = useState(false);
    const [rejectingQuestionId, setRejectingQuestionId] = useState(null);
    const [viewMode, setViewMode] = useState("resolutions");

    function updateData() {
        const token = localStorage.getItem('auth_token');
        dispatch(fetchOutcomes({ auth_token: token }));
        dispatch(fetchQuestionsByStatus({ auth_token: token, status: 'pending' })).then((response) => {
            setSelectedOutcome('All');
        });
    }

    useEffect(() => {
        updateData();
    }, []);

    function reselectData() {
        setSelectedOutcome('All');
    }

    function handleCheck(property, question) {
        const token = localStorage.getItem('auth_token');
        if (property === 'resolvable') {
            dispatch(acceptQuestion({ auth_token: token, id: question.id, resolvable: true })).then((response) => {
                reselectData();
            });
        } else if (property === 'relevant') {
            dispatch(acceptQuestion({ auth_token: token, id: question.id, relevant: true })).then((response) => {
                reselectData();
            });
        }
    }

    function handleReject(question) {
        setOpenRejectModal(true);
        setRejectingQuestionId(question.id);
    }

    useEffect(() => {
        let newOutcomeList = ['All'];
        for (let question of questions) {
            if (!newOutcomeList.includes(question.outcome_id)) {
                newOutcomeList.push(question.outcome_id);
            }
        }
        setOutcomeList(newOutcomeList);
        if (!selectedOutcome in newOutcomeList) {
            setSelectedOutcome('All');
        } else if (selectedOutcome === 'All') {
            setFilteredQuestions(questions);
        } else {
            setFilteredQuestions(questions.filter((question) => question.outcome_id == selectedOutcome));
        }
    }, [questions]);

    const handleOutcomeChange = (event) => {
        setSelectedOutcome(event.target.value);
        if (event.target.value === 'All') {
            setFilteredQuestions(questions);
        } else {
            setFilteredQuestions(questions.filter((question) => question.outcome_id == event.target.value));
        }
    };

    const toggleViewMode = () => {
        setViewMode((prevMode) => (prevMode === "resolutions" ? "pending" : "resolutions"));
    };

    const renderSwitchButton = () => {
        return (
            <Button
                onClick={toggleViewMode}
                variant="contained"
                color={viewMode === "resolutions" ? "primary" : "secondary"}
                sx={{
                    width: '170px',
                    transition: 'background-color 0.3s ease-in-out, transform 0.2s',
                    '&:hover': {
                        transform: 'scale(1.05)',
                    },
                    borderRadius: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    px: 2,
                    '@media (max-width: 600px)': {
                        fontSize: '0.75rem',
                        px: 1,
                    },
                    '@media (max-width: 400px)': {
                        fontSize: '0.5rem',
                        px: 0.5,
                    },
                }}
            >
                <CompareArrows sx={{ mr: 1 }} />
                {viewMode === "resolutions" ? "Resolutions" : "Pending Qs"}
            </Button>
        );
    };

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedQuestions = filteredQuestions.sort((a, b) => {
        if (order === 'asc') {
            return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
            return a[orderBy] > b[orderBy] ? -1 : 1;
        }
    });

    let content;
    if (questionStatus === 'loading') {
        content = (
            <div className="text-center">
                <ClipLoader color="#f87171" loading={true} size={100} />
            </div>
        );
    } else if (questions) {
        content = (
            <Card className="flex flex-col relative shrink-0 box-border items-center p-5 m-5">
                <Table overflow="auto">
                    <TableHead>
                        <TableRow>
                            <TableCell sortDirection={orderBy === 'resolution_criteria' ? order : false} >
                                <TableSortLabel
                                    active={orderBy === 'resolution_criteria'}
                                    direction={orderBy === 'resolution_criteria' ? order : 'asc'}
                                    onClick={() => handleSortRequest('resolution_criteria')}
                                >
                                    <span className='inline-block cursor-pointer hover:text-blue-500 hover font-bold'>
                                        Resolution Criteria
                                    </span>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={orderBy === 'resolution_date' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'resolution_date'}
                                    direction={orderBy === 'resolution_date' ? order : 'asc'}
                                    onClick={() => handleSortRequest('resolution_date')}
                                >
                                    <span className='inline-block cursor-pointer hover:text-blue-500 hover font-bold'>
                                        Resolution Date
                                    </span>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={orderBy === 'outcome_id' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'outcome_id'}
                                    direction={orderBy === 'outcome_id' ? order : 'asc'}
                                    onClick={() => handleSortRequest('outcome_id')}
                                >
                                    <span className='inline-block cursor-pointer hover:text-blue-500 hover font-bold'>
                                        Parent Outcome
                                    </span>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={orderBy === 'resolvable' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'resolvable'}
                                    direction={orderBy === 'resolvable' ? order : 'asc'}
                                    onClick={() => handleSortRequest('resolvable')}
                                >
                                    <span className='inline-block cursor-pointer hover:text-blue-500 hover font-bold'>
                                        Is Resolvable
                                    </span>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={orderBy === 'relevant' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'relevant'}
                                    direction={orderBy === 'relevant' ? order : 'asc'}
                                    onClick={() => handleSortRequest('relevant')}
                                >
                                    <span className='inline-block cursor-pointer hover:text-blue-500 hover font-bold'>
                                        Is Relevant
                                    </span>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                    <span className='inline-block cursor-pointer hover:text-blue-500 hover font-bold'>

                                    </span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedQuestions.map((question) => (
                            <TableRow key={question.id}>
                                <TableCell className='max-width-20vw overflow-hidden'>
                                    <a href={`/question/${question.id}`} target="_blank" rel="noreferrer" className='hover:text-blue-500'>
                                        {question.resolution_criteria}
                                        <OpenInNew className='inline-block ml-1' sx={{ fontSize: 16 }} />
                                    </a>
                                </TableCell>
                                <TableCell className='max-width-20vw overflow-hidden'>{question.resolution_date.end ? question.resolution_date.end : question.resolution_date}</TableCell>
                                <TableCell className='max-width-20vw overflow-hidden'>{outcomes[question.outcome_id]?.title}</TableCell>
                                <TableCell className='max-width-20vw'>
                                    <Checkbox checked={question.resolvable == "True"} onChange={() => { handleCheck('resolvable', question) }} disabled={question.resolvable == "True"} />
                                </TableCell>
                                <TableCell className='max-width-20vw overflow-hidden'>
                                    <Checkbox checked={question.relevant == "True"} disabled={question.relevant == "True"} onChange={() => { handleCheck('relevant', question) }} />
                                </TableCell>
                                <TableCell className='max-width-20vw overflow-hidden'>
                                    <Button color="error" onClick={() => { handleReject(question) }} variant='contained'>
                                        Reject
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Card>
        );
    }

    return (
        <div className="ModerateQuestionView mt-5">
            <div className="flex ml-10 mt-7 items-center">
                <HierarchicalBreadcrumbs moderate={true} />
            </div>

            <Card className="flex flex-col relative shrink-0 box-border items-center p-5 m-5">
                <div className="grid grid-cols-5 items-center w-full">
                    <div></div>
                    <Typography variant="h4" sx={{ mb: 2 }} className="text-center col-span-3">
                        <Gavel sx={{ mr: 2 }} />
                        <strong>Moderate Questions</strong>
                    </Typography>
                    <div className="flex justify-end">
                        {renderSwitchButton()}
                    </div>
                </div>
                <Typography variant="subtitle1" className="text-center">
                    All questions in pending state are displayed here. Once marked as both resolvable and relevant, they will be removed from this view. If a question is not relevant, not resolvable or a duplicate, please reject it by clicking the reject button and providing a reason.
                    <br />
                    More information about the question can be found by clicking on the question link.
                    <br />
                    To filter questions by outcome, select an outcome from the dropdown below.
                </Typography>
            </Card>

            <div className="flex mx-10 justify-between">
                <FormControl sx={{ minWidth: 120, maxWidth: 500 }}>
                    <InputLabel>Outcome</InputLabel>
                    <Select
                        native
                        label="Outcome"
                        value={selectedOutcome}
                        onChange={(event) => {
                            handleOutcomeChange(event);
                        }}
                    >
                        {outcomeList.map((outcome, index) => (
                            <option key={index} value={outcome}>
                                {outcome === 'All' ? 'All' : outcomes[outcome]?.title}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" onClick={updateData}>
                    <Sync sx={{ mr: 1 }} />
                    Refresh
                </Button>
            </div>

            <section className="moderate-question-view mt-5">
                {content}
            </section>
            {(openRejectModal) ? 
                <RejectQuestionModal shown={openRejectModal} close={() => setOpenRejectModal(false)} questionId={rejectingQuestionId} />
            : null}
        </div>
    );
}
