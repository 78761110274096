import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';

import ProfileCard from '../../components/cards/ProfileCard';
import RegisterUserModal from '../../components/modals/RegisterUserModal';

import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, selectAllUsers } from '../../store/slices/userSlice';

import { Button, Divider, useTheme, TextField, Card } from '@mui/material';
import { useNavigate } from 'react-router';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';

export default function UsersView() {
  const { isLoggedIn, userData } = useContext(AuthContext);
  const [selectedUser, setSelectedUser] = useState(null);
  const [registerDisabled, setRegisterDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const users = useSelector((state) => selectAllUsers(state));
  const usersStatus = useSelector((state) => state.users.status);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (usersStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchUsers(token));
    }
  }, [usersStatus, dispatch]);


  const registerUser = () => {
    setShowModal(true);
    setRegisterDisabled(true);
  };

  const handleOnSelect = (item) => {
    if (item == undefined) {
      setSelectedUser(null);
      setValue(null);
    } else {
      setSelectedUser(item);
      setValue(item);
    }
  };

  return (
    <div className="UserSettingsView m-5">
      {showModal && isLoggedIn && userData.role === 'Admin' && (
        <RegisterUserModal
          shown={showModal}
          close={() => {
            setShowModal(false);
          }}
          setRegisterDisabled={(val) => {
            setRegisterDisabled(val);
          }}
        />
      )}
      <div className="flex ml-5 justify-between items-center my-2">
        <HierarchicalBreadcrumbs outcomes={true} users={true} />
        <div className="flex">
          <Button
            variant="contained"
            onClick={() => {
              navigate('/user-groups');
            }}
            size="medium"
            sx={{ mx: 1 }}>
            User Groups
          </Button>
          {userData?.role === 'Admin' && (
            <Button
              variant="contained"
              onClick={registerUser}
              disabled={registerDisabled}
              sx={{ mx: 1 }}>
              Register New User
            </Button>
          )}
        </div>
      </div>

      <Card sx={{ py: 2, m: 3, borderRadius: '0.5rem' }}>
        <div className="mt-4 mx-4">
          <h3 className="text-md font-semibold mb-3">Search for Users</h3>
          <Autocomplete
            autoHighlight
            options={users}
            clearOnBlur={false}
            value={value}
            onChange={(event, newValue) => {
              handleOnSelect(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            getOptionLabel={(user) => user.username}
            renderInput={(params) =>
              <div className="flex flex-row items-center">
                <TextField {...params} label="Username" />
                <SearchIcon
                  sx={{ color: 'action.active', mx: 1.5 }}
                  id='search-button'
                  onClick={() => handleOnSelect(users.find((user) => user.username == inputValue))} />
              </div>
            }
          />
        </div>
      </Card>
      <Divider sx={{ mt: 3, mb: 2 }} />

      {selectedUser ? (
        <div className="flex w-full justify-center">
          <ProfileCard userId={selectedUser.id} />
        </div>
      ) : (
        <p className="text-sm m-10 text-center">
          Enter username to view user information.
        </p>
      )}
    </div>
  );
}
