
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectQuestionFilter,
  toggleQuestionFilter
} from '../../store/slices/outcomeSlice';

import { useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

export default function QuestionStatusFilter({
  filters,
  outcomeId,
  className
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const questionFilter = useSelector((state) =>
    selectQuestionFilter(state, outcomeId)
  );

  const isFilterSelected = (type) => {
    return questionFilter[type];
  };

  const filterClick = (event) => {
    let payload = { outcomeId: outcomeId };
    payload[event.target.value] = true;
    dispatch(toggleQuestionFilter(payload));
  };

  const getFilterColours = (status) => {
    switch (status) {
      case 'pending':
        return {
          color: theme.palette.statuses.dark1,
          '&.Mui-checked': {
            color: theme.palette.statuses.mid1
          }
        };
      case 'rejected':
        return {
          color: theme.palette.statuses.dark5,
          '&.Mui-checked': {
            color: theme.palette.statuses.mid5
          }
        };
      case 'notSubmitted':
        return {
          color: theme.palette.statuses.dark2,
          '&.Mui-checked': {
            color: theme.palette.statuses.mid2
          }
        };
      case 'accepted':
        return {
          color: theme.palette.statuses.dark3,
          '&.Mui-checked': {
            color: theme.palette.statuses.mid3
          }
        };
      case 'submitted':
        return {
          color: theme.palette.statuses.dark3,
          '&.Mui-checked': {
            color: theme.palette.statuses.mid3
          }
        };
      case 'closed':
        return {
          color: theme.palette.statuses.dark4,
          '&.Mui-checked': {
            color: theme.palette.statuses.mid4
          }
        };
      case 'duplicate':
        return {
          color: theme.palette.statuses.dark6,
          '&.Mui-checked': {
            color: theme.palette.statuses.mid6
          }
        };
      default:
        return {
          color: theme.palette.statuses.dark7,
          '&.Mui-checked': {
            color: theme.palette.statuses.mid7
          }
        };
    }
  };

  let filter;
  filter = filters.map((filter, index) => (
    <FormControlLabel
      key={index}
      sx={{ mx: 0.3 }}
      control={
        <Checkbox
          checked={isFilterSelected(filter.key)}
          onChange={(event) => filterClick(event)}
          value={filter.key}
          style={{ marginRight: '0px' }}
          sx={Object.assign(getFilterColours(filter.key), { p: 0.5 })}
        />
      }
      label={filter.value}
    />
  ));

  return (
    <div className={`${className}`}>
      <FormGroup sx={{ display: 'flex', justifyContent: 'end' }} row>
        {filter}
      </FormGroup>
    </div>
  );
}
