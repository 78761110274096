import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Switch,
  Tab,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ukLocale from 'date-fns/locale/en-GB';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import { AuthContext, checkTokenStatus } from '../../App';
import { addNewOutcome } from '../../store/slices/outcomeSlice';
import ConfirmationModal from '../modals/ConfirmationModal';
import MultiSelectDropdown from '../other/MultiSelectDropdown';
import SelectDropdown from '../other/SelectDropdown';

import {
  fetchBucketPresets,
  selectAllPresets,
  selectPresetByName
} from '../../store/slices/bucketSlice';
import {
  addOutcomesToUserGroup,
  fetchUserGroups,
  selectAllUserGroups,
  selectGroupNames
} from '../../store/slices/userGroupSlice';
import HelpLink from '../other/HelpLink';
import CreateOutcomeInternalSliders from './CreateOutcomeInternalSliders';
import InfoTooltip from './InfoTooltip';
import OutcomeCreationDatesPicker from './OutcomeCreationDatesPicker';
import OutcomeTimeline from './OutcomeTimeline';

export default function CreateOutcome() {
  const dispatch = useDispatch();
  const { setIsLoggedIn, userData } = useContext(AuthContext);
  var localOutcomeObj = JSON.parse(
    localStorage.getItem('outcome:' + userData.username)
  );
  if (localOutcomeObj === null) {
    localOutcomeObj = {};
  }
  const userGroupStatus = useSelector((state) => state.userGroups.status);
  const bucketPresetStatus = useSelector(
    (state) => state.buckets.presets.status
  );
  const outcomeTitleMaxLength = 30;
  const outcomeSummaryMaxLength = 2000;

  const [workflowTab, setWorkflowTab] = useState('1');

  useEffect(() => {
    if (userGroupStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchUserGroups(token));
    }
  }, [userGroupStatus, dispatch]);

  useEffect(() => {
    if (bucketPresetStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchBucketPresets(token));
    }
  }, [bucketPresetStatus]);

  const groups = useSelector((state) => selectAllUserGroups(state));
  const groupNames = useSelector((state) => selectGroupNames(state));
  const presets = useSelector((state) => selectAllPresets(state));
  const presetNames = presets
    ?.filter((preset) => preset.assignable === 'True')
    ?.map((preset) => preset.name);
  const [title, setTitle] = useState(
    localOutcomeObj.title ? localOutcomeObj.title : ''
  );
  const [description, setDescription] = useState(
    localOutcomeObj.description ? localOutcomeObj.description : ''
  );
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState('Default');
  const [questionSubmissionLimit, setQuestionSubmissionLimit] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [continueForecasting, setContinueForecasting] = useState(false);
  const [externalForecasting, setExternalForecasting] = useState(false);
  const [validDateSubmission, setValidDateSubmission] = useState(false);
  const selectedPresetId = useSelector((state) =>
    selectPresetByName(state, selectedPreset)
  );
  const [outcomeOwnerCanForecast, setOutcomeOwnerCanForecast] = useState(false);
  const [outcomeOwnerCanQuestion, setOutcomeOwnerCanQuestion] = useState(false);
  const [showSubmitConfirmModal, setShowSubmitConfirmModal] = useState(false);

  useEffect(() => {
    moment.relativeTimeThreshold('w', 8);
    moment.relativeTimeThreshold('d', 14);
  }, []);

  const initialiseEndDate = () => {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 90);
    return date.getTime();
  };
  const getInternalGenerationEndDate = () => {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 15);
    return date.getTime();
  };
  const getInternalForecastingStartDate = () => {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate());
    return date.getTime();
  };
  const getExternalGenerationEndDate = () => {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 15);
    return date.getTime();
  };
  const getExternalForecastingStartDate = () => {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 30);
    return date.getTime();
  };

  const [outcomeEndDate, setOutcomeEndDate] = useState(initialiseEndDate());
  const [forecastingStartDate, setForecastingStartDate] = useState(
    getInternalForecastingStartDate()
  );
  const [generationEndDate, setGenerationEndDate] = useState(
    getInternalGenerationEndDate()
  );
  const openOutcomeView = (outcomeId) => {
    navigate(`/outcome/${outcomeId}`);
  };

  const changeOutcomeEndDate = (outcomeEndDate) => {
    let date = new Date(outcomeEndDate);
    date.setHours(0, 0, 0, 0);
    setOutcomeEndDate(date.getTime());
  };
  const changeTitle = (event) => {
    updateLocalStorage('title', event.target.value);
    setTitle(event.target.value);
  };
  const changeDescription = (event) => {
    updateLocalStorage('description', event.target.value);
    setDescription(event.target.value);
  };
  const changeQuestionSubmissionLimit = (event) => {
    const submissionLimit = event.target.validity.valid
      ? event.target.value
      : questionSubmissionLimit;
    setQuestionSubmissionLimit(submissionLimit);
  };
  const toggleExternalForecasting = () => {
    // when toggling external forecasting we need to reset the dates in order to not break either date selector
    if (externalForecasting) {
      setForecastingStartDate(getInternalForecastingStartDate());
      setGenerationEndDate(getInternalGenerationEndDate());
    } else {
      setForecastingStartDate(getExternalForecastingStartDate());
      setGenerationEndDate(getExternalGenerationEndDate());
    }
    setOutcomeEndDate(initialiseEndDate());

    setExternalForecasting(!externalForecasting);
  };
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const updateLocalStorage = (field, value) => {
    // outcome id + username is the composite unique field for items saved in local storage (this makes drafts unique per outcome per user)
    // object is saved in stringified format so we parse it after fetching
    var localOutcomeObj = JSON.parse(
      localStorage.getItem('outcome:' + userData.username)
    );
    if (localOutcomeObj === null) {
      localOutcomeObj = {};
    }
    // update question object's specified field
    localOutcomeObj[field] = value;
    // save updated object in local storage (stringified format)
    localStorage.setItem(
      'outcome:' + userData.username,
      JSON.stringify(localOutcomeObj)
    );
  };

  const canCreate =
    [
      title,
      description,
      checkTokenStatus(),
      outcomeEndDate,
      forecastingStartDate,
      externalForecasting ? questionSubmissionLimit : true,
      generationEndDate
    ].every(Boolean) &&
    (addRequestStatus === 'idle' || addRequestStatus === 'error');

  const submitOutcome = async () => {
    if (canCreate) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setAddRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let outcomeId;
        let payload = {
          title: title,
          description: description,
          end_date: moment(outcomeEndDate).format('YYYY-MM-DD'),
          forecasting_start_date:
            moment(forecastingStartDate).format('YYYY-MM-DD'),
          generation_end_date: moment(generationEndDate).format('YYYY-MM-DD'),
          question_submission_limit: externalForecasting
            ? questionSubmissionLimit
            : null,
          continue_forecasting: continueForecasting,
          bucket_preset_id: selectedPresetId.id,
          forecast_mechanism: externalForecasting ? 'manual' : 'derived', // if external forecasting is true, set forecast_mechanism to manual
          owner_can_forecast: outcomeOwnerCanForecast,
          owner_can_question: outcomeOwnerCanQuestion,
          auth_token: token
        };
        await dispatch(addNewOutcome(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setTitle('');
              setDescription('');
              setAddRequestStatus('idle');
              isMounted = false;
            }
            if (response.status === 'success') {
              // clears local storage 'draft' when outcome is submitted successfully
              localStorage.removeItem('outcome:' + userData.username);
              outcomeId = response.data.id;
              if (outcomeId) {
                groups.forEach((group) => {
                  if (selectedGroups.includes(group.name)) {
                    const groupPayload = {
                      id: group.id,
                      auth_token: token,
                      outcome_ids: [outcomeId]
                    };
                    dispatch(addOutcomesToUserGroup(groupPayload));
                  }
                });
                setSelectedGroups([]);
              }
              openOutcomeView(outcomeId);
            }
          })
          .catch((err) => {
            setErrorMessage(`Failed to create outcome: ${err.message}`);
            setAddRequestStatus('error');
          });
      } catch (err) {
        setErrorMessage(`Failed to create outcome: ${err.message}`);
        setAddRequestStatus('error');
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (
        title &&
        description &&
        outcomeEndDate &&
        (externalForecasting ? questionSubmissionLimit : true) &&
        forecastingStartDate
      )
        setErrorMessage('Outcome could not be created.');
      if (!title) setErrorMessage('Please set a title before submitting.');
      else if (!description)
        setErrorMessage('Please set a Description before submitting.');
      else if (!outcomeEndDate)
        setErrorMessage('Please set an Outcome end date before submitting');
      else if (!forecastingStartDate)
        setErrorMessage(
          'Please set a Forecasting Start Date before submitting'
        );
      else if (externalForecasting && !questionSubmissionLimit)
        setErrorMessage(
          'Please set a Question Submission Limit before submitting.'
        );
    }
  };

  const handleTabChange = (event, newValue) => {
    setWorkflowTab(newValue);
  };

  const calculateTimeBetweenDeadlines = () => {
    return moment(forecastingStartDate).from(outcomeEndDate, true);
  };

  return (
    <Card sx={{ my: 2 }}>
      <div className="xs:p-2 md:px-3 md:py-4 m-5">
        <div className="flex items-center mb-1">
          <Typography sx={{ fontWeight: 'bold' }}>Submit an Outcome</Typography>
          <HelpLink
            section="outcome-owner"
            position={'WhatAmISupposedToDoWithHivemind'}
          />
        </div>
        <Divider />

        <div>
          <div className="flex items-center mt-3">
            <Typography className="">Title</Typography>
          </div>
          <TextField
            placeholder="The title of the outcome."
            type="text"
            variant="outlined"
            value={title}
            id="outcomeTitle"
            onChange={(event) => changeTitle(event)}
            sx={{ my: 1.2 }}
            helperText={`${title.length}/${outcomeTitleMaxLength}`}
            error={title.length > outcomeTitleMaxLength}
            className="w-full"
          />
        </div>

        <div>
          <div className="flex mt-3">
            <Typography className="">Description</Typography>
          </div>
          <TextField
            placeholder="A text description that describes your decision, objectives, and any other key concerns."
            id="outcomeDescription"
            variant="outlined"
            multiline
            value={description}
            onChange={(event) => changeDescription(event)}
            rows="4"
            sx={{ my: 1.2 }}
            helperText={`${description.length}/${outcomeSummaryMaxLength}`}
            error={description.length > outcomeSummaryMaxLength}
            className="w-full"></TextField>
        </div>
        <div className="flex mt-3">
          <Typography sx={{ mb: 1.2 }}>Workflow</Typography>
          <div className="ml-1">
            <InfoTooltip text="The timeframes that dictate when the user base will engage with activities on this Outcome." />
          </div>
        </div>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={workflowTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example">
                <Tab label="Timeline" value="1" />
                <Tab label="Classic" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className="my-2">
                {externalForecasting ? (
                  <OutcomeTimeline
                    generationEndDate={generationEndDate}
                    setGenerationEndDate={setGenerationEndDate}
                    forecastingStartDate={forecastingStartDate}
                    setForecastingStartDate={setForecastingStartDate}
                    outcomeEndDate={outcomeEndDate}
                    setOutcomeEndDate={setOutcomeEndDate}
                  />
                ) : (
                  <div>
                    <CreateOutcomeInternalSliders
                      outcomeEndDate={outcomeEndDate}
                      setOutcomeEndDate={changeOutcomeEndDate}
                      generationEndDate={generationEndDate}
                      setGenerationEndDate={setGenerationEndDate}
                      forecastingStartDate={forecastingStartDate}
                      setForecastingStartDate={setForecastingStartDate}
                      setValidDateSubmission={setValidDateSubmission}
                    />
                  </div>
                )}
              </div>
            </TabPanel>
            <TabPanel value="2">
              {externalForecasting ? (
                <>
                  <div className="flex justify-evenly items-center mt-5 mb-1">
                    <div className="ml-2 flex text-center">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ukLocale}>
                        <DatePicker
                          clearable={false}
                          label="Forecasting Start Date"
                          value={forecastingStartDate}
                          onChange={(newValue) => {
                            setForecastingStartDate(newValue);
                          }}
                          maxDate={outcomeEndDate}
                          textField={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <div className="ml-1">
                        <InfoTooltip text="The date on which Question generation activity will stop and Questions will be submitted to the forecasting platform." />
                      </div>
                    </div>
                    <div className="mr-2 flex">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ukLocale}>
                        <DatePicker
                          clearable={false}
                          label="Outcome End Date"
                          value={outcomeEndDate}
                          onChange={(newValue) => {
                            setOutcomeEndDate(newValue);
                          }}
                          minDate={Date.now()}
                          textField={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <div className="ml-1">
                        <InfoTooltip text="The date after which the Outcome is no longer valid, and when all the questions and evaluations will be scored." />
                      </div>
                    </div>
                  </div>
                  <div className="px-2 my-3 text-center">
                    <Typography
                      sx={{ fontSize: '0.795rem' }}
                      className="text-xs font-medium">{`There is ${calculateTimeBetweenDeadlines()} between the above Forecasting Start Date and the Outcome End Date. This gives ${calculateTimeBetweenDeadlines()} for Question forecasting.`}</Typography>
                  </div>
                </>
              ) : (
                <div className="my-2">
                  <OutcomeCreationDatesPicker
                    initialGenerationEndDate={generationEndDate}
                    setGenerationEndDate={setGenerationEndDate}
                    initialForecastingStartDate={forecastingStartDate}
                    setForecastingStartDate={setForecastingStartDate}
                    initialOutcomeEndDate={outcomeEndDate}
                    setOutcomeEndDate={setOutcomeEndDate}
                    continueForecasting={continueForecasting}
                    validSubmission={validDateSubmission}
                    setValidSubmission={setValidDateSubmission}
                  />
                </div>
              )}
            </TabPanel>
          </TabContext>
        </Box>
        <div>
          <div className="flex flex-row" onClick={() => toggleCollapsed()}>
            <Typography className="pr-2">Advanced Settings</Typography>
            <button className="mr-1">
              {collapsed ? (
                <BsChevronDown size={15} />
              ) : (
                <BsChevronUp size={15} />
              )}
            </button>
          </div>
          <Collapse in={!collapsed}>
            <div>
              <div className="flex mt-3">
                <Typography className="">Bucket Preset</Typography>
                <div className="ml-1">
                  <InfoTooltip text="Select the Preset of Forecasting Buckets for this Outcome" />
                </div>
              </div>
              <SelectDropdown
                label={'Select Preset'}
                options={presetNames}
                selectedValue={selectedPreset}
                onSelectedValueChange={setSelectedPreset}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="mt-3">
                <div className="flex">
                  <Typography className="">
                    Outcome Owner Can Forecast?
                  </Typography>
                  <div className="ml-1">
                    <InfoTooltip text="Indicates whether the Outcome owner can forecast on this Outcome." />
                  </div>
                </div>
                <Switch
                  checked={outcomeOwnerCanForecast}
                  onChange={() =>
                    setOutcomeOwnerCanForecast(!outcomeOwnerCanForecast)
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>

              <div className="mt-3">
                <div className="flex">
                  <Typography className="">
                    Outcome Owner Can Create A Question?
                  </Typography>
                  <div className="ml-1">
                    <InfoTooltip text="Indicates whether the Outcome owner can submit questions on this Outcome." />
                  </div>
                </div>
                <Switch
                  checked={outcomeOwnerCanQuestion}
                  onChange={() =>
                    setOutcomeOwnerCanQuestion(!outcomeOwnerCanQuestion)
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>

              <div className="mt-3">
                <div className="flex">
                  <Typography className="">
                    Continue forecasting past end date?
                  </Typography>
                  <div className="ml-1">
                    <InfoTooltip text="If this option is enabled, Hivemind will continue forecasting questions past your Outcome's end date." />
                  </div>
                </div>
                <Switch
                  checked={continueForecasting}
                  onChange={() => setContinueForecasting(!continueForecasting)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              <div className="mr-3 mt-3">
                <div className="flex">
                  <Typography className="">External Forecasting?</Typography>
                  <div className="ml-1">
                    <InfoTooltip text="Indicates the expected forecast elicitation method. Setting this value to true means questions will be posted to an external forecasting platform and the forecasts will be manually entered into Hivemind by admins." />
                  </div>
                </div>
                <Switch
                  checked={externalForecasting}
                  onChange={toggleExternalForecasting}
                />
              </div>
              {externalForecasting && (
                <div className="mr-3 mt-3">
                  <div className="flex">
                    <Typography className="">
                      Question Submission Limit
                    </Typography>
                    <div className="ml-1">
                      <InfoTooltip text="The maximum number of Questions that will be submitted to the forecasting platform relating to this Outcome." />
                    </div>
                  </div>
                  <TextField
                    type="text"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    size="small"
                    onChange={(event) => changeQuestionSubmissionLimit(event)}
                    value={questionSubmissionLimit}
                    sx={{ my: 0.6 }}
                    className="w-1/6"></TextField>
                </div>
              )}
            </div>
            <div>
              <div className="flex mt-3">
                <Typography className="">Outcome Group(s)</Typography>
                <div className="ml-1">
                  <InfoTooltip text="Groups that will be able to see and participate on this Outcome" />
                </div>
              </div>
              <MultiSelectDropdown
                label={'Select group(s)'}
                options={groupNames}
                selectedValues={selectedGroups}
                onSelectedValuesChange={setSelectedGroups}
              />
            </div>
          </Collapse>
        </div>
      </div>
      <div className="flex w-full justify-end">
        <div className="m-5 inline-flex items-center">
        <Typography color="error" sx={{ mr: 2 }}>
          {errorMessage}
        </Typography>
          <Button
            variant="contained"
            onClick={() => {
              // checks whether user has left dates as the default, if so it will display a warning modal
              var default_dates;
              if (externalForecasting) {
                if (
                  generationEndDate === getExternalGenerationEndDate() &&
                  forecastingStartDate === getExternalForecastingStartDate() &&
                  outcomeEndDate === initialiseEndDate()
                ) {
                  default_dates = true;
                } else {
                  default_dates = false;
                }
              } else {
                if (
                  generationEndDate === getInternalGenerationEndDate() &&
                  forecastingStartDate === getInternalForecastingStartDate() &&
                  outcomeEndDate === initialiseEndDate()
                ) {
                  default_dates = true;
                } else {
                  default_dates = false;
                }
              }
              if (default_dates) {
                setShowSubmitConfirmModal(true);
              } else {
                submitOutcome();
              }
            }}>
            Submit
          </Button>
        </div>
      </div>
      {showSubmitConfirmModal && (
        <ConfirmationModal
          shown={showSubmitConfirmModal}
          close={() => {
            setShowSubmitConfirmModal(false);
          }}
          confirm={() => {
            setShowSubmitConfirmModal(false);
            submitOutcome();
          }}
          confirmationMessage="It looks like you're using the default dates for this outcome. Please check the dates are correct and suitable for your outcome. You may not be able to change these values later."
        />
      )}
    </Card>
  );
}
