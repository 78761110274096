import { Button, Card, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import DataImporter from '../../components/other/DataImporter';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import Setting from '../../components/other/Setting';
import BucketConfigurationModal from '../../components/modals/BucketConfigurationModal.jsx';

import {
  fetchSettingGroups,
  fetchSettings,
  selectAllSettingGroups
} from '../../store/slices/settingsSlice';
import BucketPresetModal from '../../components/modals/BucketPresetModal';

export default function AdminSettingsView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settingGroups = useSelector((state) => selectAllSettingGroups(state));
  const settingsStatus = useSelector((state) => state.settings.status);
  const settingGroupsStatus = useSelector(
    (state) => state.settings.settingGroups.status
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [openBucketPresetModal, setOpenBucketPresetModal] = useState(false);
  const [openBucketConfigurationModal, setOpenBucketConfigurationModal] = useState(false);

  useEffect(() => {
    dispatch(fetchSettings());
    dispatch(fetchSettingGroups());
  }, [dispatch]);

  if (settingsStatus === 'loading' || settingGroupsStatus === 'loading') {
    return (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  } else if (
    settingsStatus === 'succeeded' &&
    settingGroupsStatus === 'succeeded'
  ) {
    return (
      <div className="SiteSettingsView">
        <BucketConfigurationModal
          open={openBucketConfigurationModal}
          setOpen={setOpenBucketConfigurationModal}
        />
        <BucketPresetModal
          shown={openBucketPresetModal}
          close={() => {setOpenBucketPresetModal(false)}}
        />
        <div className="m-5">
          <div className="flex justify-between ml-5 mt-7 items-center">
            <HierarchicalBreadcrumbs outcomes={true} settings={true} />
          </div>
          <div className="w-full flex justify-center">
            <Card className="flex w-1/2 p-1 items-center justify-end flex-col">
              <div className="w-full p-3 mt-2">
                <Typography
                  sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}
                  className="text-center"
                >
                  Administrator Settings
                </Typography>
                {errorMessage && (
                  <Typography color="error">{errorMessage}</Typography>
                )}
                <div className="my-1">
                  {settingGroups.map((group) => {
                    return (
                      <div className="flex flex-col" key={group.id}>
                        <Divider />
                        <Typography
                          sx={{ mt: 0.5, ml: 2 }}
                          color="text.secondary"
                          display="block"
                          variant="caption"
                        >
                          {group.name}
                        </Typography>
                        <div className="p-2">
                          {group.settings.map((setting) => {
                            return <Setting key={setting.id} id={setting.id} />;
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div>
                  <DataImporter />
                </div>
                <div className="my-1">
                  <div className="flex flex-col">
                    <Divider />
                    <Typography
                      sx={{ mt: 0.5, ml: 2 }}
                      color="text.secondary"
                      display="block"
                      variant="caption"
                    >
                      User Groups
                    </Typography>
                    <div className="p-2 flex items-center justify-center">
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate('/user-groups');
                        }}
                        size="medium"
                      >
                        Open User Groups
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="my-1">
                  <div className="flex flex-col">
                    <Divider />
                    <Typography
                      sx={{ mt: 0.5, ml: 2 }}
                      color="text.secondary"
                      display="block"
                      variant="caption"
                    >
                    Buckets
                    </Typography>
                    <div className="p-2 flex items-center justify-center">
                      <Button
                        variant="contained"
                        onClick={() => {
                          setOpenBucketConfigurationModal(true);
                        }}
                        size="medium"
                      >
                        Manage Bucket Configurations
                      </Button>
                    </div>
                    <div className="p-2 flex items-center justify-center">
                      <Button
                        variant="contained"
                        onClick={() => {
                          setOpenBucketPresetModal(true);
                        }}
                        size="medium"
                      >
                        Manage Bucket Presets
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
