import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Button, Card, TextField, Typography, Collapse, IconButton, Tooltip } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { FiEdit } from "react-icons/fi";
import "./styles.css";
import { addNewBucketConfiguration, fetchBucketConfigurations, selectAllConfigurations, updateBucketConfiguration, fetchBuckets, deleteBucketConfiguration } from "../../store/slices/bucketSlice";
import {AuthContext, checkTokenStatus } from "../../App";
import { BsBucketFill } from "react-icons/bs";
import { FiCheck, FiX } from "react-icons/fi";
import ConfirmationModal from "./ConfirmationModal";
import BucketConfigurationForm from "./BucketConfigurationForm";
import BucketList from "../lists/BucketList";
import { Delete } from "@mui/icons-material";


export default function BucketConfigurationModal({ open, setOpen }) {
    const dispatch = useDispatch();
    const { setIsLoggedIn } = useContext(AuthContext);
    const bucketConfigurations = useSelector((state) => selectAllConfigurations(state));
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedConfiguration, setSelectedConfiguration] = useState(null);
    const [newBucketConfiguration, setNewBucketConfiguration] = useState({ name: "", bucket_name: [], bucket_upper_limit: [], bucket_lower_limit: [] });
    const [showNewConfigurationForm, setShowNewConfigurationForm] = useState(false);
    const [editConfiguration, setEditConfiguration] = useState(false);
    const [showDeleteBucketConfigurationForm, setShowDeleteBucketConfigurationForm] = useState(false);
    const [showBucketConfigurationWarning, setShowBucketConfigurationWarning] = useState(false);
    const [showBuckets, setShowBuckets] = useState(false);
    const configsPerPage = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const pages = Math.ceil(bucketConfigurations.length / configsPerPage);

    const closeForms = () => {
        setShowNewConfigurationForm(false);
        setShowDeleteBucketConfigurationForm(false);
    };

    useEffect(() => {
        if (errorMessage === "") {
            closeForms();
        }
    }, [errorMessage]);

    const checkPages = () => {
        if (currentPage > pages && bucketConfigurations.length > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        checkPages();
    }, [bucketConfigurations]);

    useEffect(() => {
        setErrorMessage("")
    }, [showNewConfigurationForm])

    const fetchAuthToken = async () => {
        const token = localStorage.getItem("auth_token");
        const status = await checkTokenStatus(token);
        if (status === 401) {
            setIsLoggedIn(false);
            return <Navigate to="/login" />;
        }
        return token;
    }

    useEffect(() => {
        fetchAuthToken().then((token) => {
            dispatch(fetchBucketConfigurations(token));
        });
    }, [dispatch]);

    const invoke = async (functionToCall, payload) => {
        setErrorMessage("");
        await fetchAuthToken().then(async (token) => {
            try {
                await dispatch(functionToCall({ ...payload}))
                .unwrap()
                .then((response) => {
                    closeForms();
                    return response;
                });
                
            } catch (error) {
                setErrorMessage(error.message);
            } finally {
                dispatch(fetchBucketConfigurations(token));
                dispatch(fetchBuckets(token));
            }
        });
    };

    const validateBucketConfiguration = (bucket_config) => {
        if (bucket_config.buckets === undefined) {
            return false;
        }
        for (let i = 0; i < bucket_config.buckets.length; i++) {
            const bucketSum = bucket_config.buckets.reduce((sum, bucket) => sum + (bucket.upper_limit - bucket.lower_limit), 0);
            if (bucketSum !== 1) {
                return false;
            }
            
            
        return true;
        }
    };

    const createBucketConfiguration = async (bucket_config) => {
        const bucket_name = [];
        const bucket_upper_limit = [];
        const bucket_lower_limit = [];
        if (bucket_config.buckets !== undefined) {
            for (let i = 0; i < bucket_config.buckets.length; i++) {
                bucket_name.push(bucket_config.buckets[i].name);
                bucket_upper_limit.push(bucket_config.buckets[i].upper_limit);
                bucket_lower_limit.push(bucket_config.buckets[i].lower_limit);
            }
        }
        fetchAuthToken().then((token) => {
            invoke(addNewBucketConfiguration, { name: bucket_config.name, bucket_name: bucket_name, bucket_upper_limit: bucket_upper_limit, bucket_lower_limit: bucket_lower_limit, auth_token: token });
            if (errorMessage === "") {
                setNewBucketConfiguration({ name: "", bucket_name: [], bucket_upper_limit: [], bucket_lower_limit: [] });
                setShowNewConfigurationForm(false);
            }
        });
    };

    const handleAddNewBucketConfiguration = (bucket_config) => {
        setNewBucketConfiguration(bucket_config);
        if (bucket_config.name === "") {
            setErrorMessage("Please fill out configuration name");
            return;
        }
        if (!validateBucketConfiguration(bucket_config)) {
            setShowBucketConfigurationWarning(true);
            setNewBucketConfiguration(bucket_config);
        } else {
            createBucketConfiguration(bucket_config);
        }
    };

    const handleUpdateBucketConfiguration = (bucket_config) => {
        if (bucket_config === null) {
            setErrorMessage("Please select a configuration");
            return;
        }
        fetchAuthToken().then(async (token) => {
            invoke(updateBucketConfiguration, { id: bucket_config.id, name: bucket_config.name, auth_token: token });
        }
        );
    };

    const handleDeleteBucketConfiguration = () => {
        if (selectedConfiguration === null) {
            setErrorMessage("Please select a configuration");
            return;
        }
        fetchAuthToken().then((token) => {
            invoke(deleteBucketConfiguration, { id: selectedConfiguration.id, auth_token: token });
            if (errorMessage === "") {
                setSelectedConfiguration(null);
            } else {
                return;
            }
        });
        checkPages();
    };

    const handleBucketConfigurationSelect = (id) => {
        const configuration = bucketConfigurations.find((configuration) => configuration.id === id);
        setSelectedConfiguration(configuration);
    };

    if (!open) {
        return null;
    }

    return (
        <div className="modal-backdrop" onClick={() => setOpen(false)}>
            <Card
                className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
                onClick={(e) => {
                e.stopPropagation();
                }}
            >
                    <div className="modal-header flex justify-between mb-4">
                        <Typography variant="h6" className="modal-title">Manage Bucket Configurations</Typography>
                        <Button onClick={() => setOpen(false)} className="modal-close" variant="contained" color="error">Close</Button>
                    </div>
                    <div className="modal-body">
                        {showNewConfigurationForm && (
                            <BucketConfigurationForm
                                bucketConfiguration={newBucketConfiguration}
                                setBucketConfiguration={setNewBucketConfiguration}
                                onSubmit={handleAddNewBucketConfiguration}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                                setShowBucketConfigurationForm={setShowNewConfigurationForm}
                            />
                        )}
                        {showDeleteBucketConfigurationForm && (
                            <ConfirmationModal
                                shown={showDeleteBucketConfigurationForm}
                                close={() => setShowDeleteBucketConfigurationForm(false)}
                                confirmationMessage="Are you sure you want to delete this configuration? This action cannot be undone."
                                confirm={() => {handleDeleteBucketConfiguration(); setShowDeleteBucketConfigurationForm(false);}}
                            />
                        )}
                        {showBucketConfigurationWarning && (
                            <ConfirmationModal
                                shown={showBucketConfigurationWarning}
                                close={() => setShowBucketConfigurationWarning(false)}
                                confirmationMessage={"Are you sure you want to create this configuration? The buckets do not cover the entire probability space."}
                                confirm={() => {createBucketConfiguration({...newBucketConfiguration}); setShowBucketConfigurationWarning(false);}}
                            />
                        )}

                        <div className="flex flex-col">
                            {(bucketConfigurations.length === 0) ? (
                                <div>
                                    <Typography>No configurations found</Typography>
                                    <Button onClick={() => setShowNewConfigurationForm(true)} style={{fontSize: "12px"}}>
                                            Create one now
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <div className="flex justify-between">
                                        <TextField
                                            label="Search"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            style={{ marginTop: "-5px", width: "80%"}}
                                            size="small"
                                        />
                                        <Button onClick={() => setShowNewConfigurationForm(true)} style={{fontSize: "12px"}}>
                                            Create
                                        </Button>
                                    </div>
                                    <div className="grid grid-cols-1">
                                        {bucketConfigurations.filter((configuration) => configuration.name.toLowerCase().includes(search.toLowerCase())).slice((currentPage - 1) * configsPerPage, currentPage * configsPerPage).map((configuration) => (
                                            <Card key={configuration.id} sx={{ padding: "10px", margin: "10px", backgroundColor: "background.default"}}>
                                                <div className="flex justify-between">
                                                    <div className="flex items-center">
                                                        {editConfiguration === configuration.id ? (
                                                            <TextField
                                                                label="Name"
                                                                value={selectedConfiguration.name}
                                                                onChange={(e) => setSelectedConfiguration({ ...selectedConfiguration, name: e.target.value })}
                                                                style={{ width: "80%", margin: "10px 0px" }}
                                                                size="small"
                                                            />
                                                        ) : (
                                                            <Typography>{configuration.name}</Typography>
                                                        )
                                                        }
                                                        
                                                        <Button
                                                            variant="text"
                                                            sx={{ color: "text.primary" }}
                                                            onClick={() => {
                                                                setSelectedConfiguration(configuration);
                                                                if (editConfiguration === configuration.id) {
                                                                    if (selectedConfiguration.name !== configuration.name) {
                                                                        handleUpdateBucketConfiguration(selectedConfiguration);
                                                                    }
                                                                }
                                                                setEditConfiguration(editConfiguration === configuration.id ? null : configuration.id)
                                                            }}>
                                                            <FiEdit className="mr-1" />
                                                            {editConfiguration === configuration.id ? "Save" : "Edit"}

                                                        </Button>
                                                    </div>
                                                    <IconButton onClick={() => { setShowDeleteBucketConfigurationForm(true); handleBucketConfigurationSelect(configuration.id); }} color="error">
                                                        <Delete />
                                                    </IconButton>
                                                </div>
                                                <div className="flex items-center" style={{marginTop: "-10px"}}>
                                                    {configuration.buckets.length}x <BsBucketFill />
                                                    {(configuration.is_valid === "True") ? (
                                                        <Tooltip title="This configuration is valid" style={{ marginLeft: "5px" }}>
                                                            <div>
                                                                <FiCheck />
                                                            </div>
                                                        </Tooltip>
                                                    ) : ""}
                                                    {configuration.is_valid === "False" ? (
                                                        <Tooltip title={`This configuration is not valid: ${configuration.invalid_description}`} style={{ marginLeft: "5px" }}>
                                                            <div>
                                                                <FiX />
                                                            </div>
                                                        </Tooltip>
                                                    ) : ""}
                                                </div>
                                                <div className="flex w-full" style={{ flexDirection: "row-reverse", marginTop: "-25px"}}>
                                                    <IconButton
                                                        onClick={() => setShowBuckets(showBuckets === configuration.id ? null : configuration.id)} >
                                                            <Typography>View Buckets</Typography>
                                                        {showBuckets === configuration.id ? <ExpandLess /> : <ExpandMore />}
                                                    </IconButton>
                                                </div>
                                                <Collapse in={showBuckets === configuration.id}>
                                                    <BucketList bucketConfiguration={configuration} setBucketConfiguration={setSelectedConfiguration}/>
                                                </Collapse>
                                            </Card>
                                        ))
                                        }
                                    </div>
                                    <div className="flex justify-between">
                                        <Button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
                                        <Typography>{currentPage} of {pages}</Typography>
                                        <Button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(bucketConfigurations.length / configsPerPage)}>Next</Button>
                                    </div>
                                </div>
                            )}
                        <Typography color="error">{errorMessage}</Typography>
                    </div>
                </div>
            </Card>
        </div>
    );
}