import { Block, HighlightOff, Report } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Collapse,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { formatISO } from 'date-fns';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  rejectQuestion,
  selectQuestionById
} from '../../store/slices/outcomeSlice';
import SmallQuestionCard from '../cards/SmallQuestionCard';

export default function RejectQuestionModal({ shown, close, questionId }) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const [responseMessage, setResponseMessage] = useState('');
  const [rejectRequestStatus, setRejectRequestStatus] = useState('idle');
  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );
  const [selectedRejectionReason, setSelectedRejectionReason] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const closeModal = () => {
    close();
  };

  const isResolvable = question.resolvable == 'True';
  const isRelevant = question.relevant == 'True';

  const canReject =
    [questionId, checkTokenStatus()].every(Boolean) &&
    selectedRejectionReason !== '' &&
    rejectionReason !== null &&
    rejectRequestStatus === 'idle';

  const rejectConfirm = async () => {
    if (canReject) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setRejectRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: questionId,
          resolvable: selectedRejectionReason === 'Unresolvable' ? false : null,
          relevant: selectedRejectionReason === 'Irrelevant' ? false : null,
          duplicate: selectedRejectionReason === 'Duplicate' ? true : null,
          resolvableRejectionComment:
            selectedRejectionReason === 'Unresolvable' ? rejectionReason : null,
          relevantRejectionComment:
            selectedRejectionReason === 'Irrelevant' ? rejectionReason : null,
          duplicateRejectionComment:
            selectedRejectionReason === 'Duplicate' ? rejectionReason : null,
          auth_token: token
        };
        // only submits a value if the comment has changed or the resolvability/relevance/duplicacy has been toggled
        await dispatch(rejectQuestion(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setSelectedRejectionReason('');
              setRejectionReason(null);
            }
            if (response.status === 'success') {
              setErrorMessage('');
              setResponseMessage(
                `The question has been successfully rejected.`
              );
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to reject the question: ${err}`);
      } finally {
        if (isMounted) {
          setRejectRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (selectedRejectionReason === '') {
      setErrorMessage('Please select a Rejection Reason before confirming.');
    } else if (rejectionReason === null) {
      setErrorMessage('Please select a Rejection Reason before confirming.');
    } else {
      setErrorMessage('Failed to reject the question.');
    }
  };

  return shown ? (
    <div
      className="modal-backdrop"
      onClick={() => {
        // close modal when outside of modal is clicked
      }}>
      <Card
        className="modal-content w-full sm:w-6/7 md:w-5/6 lg:w-3/5 xl:w-3/5 2xl:w-3/5"
        sx={{ overflowY: 'auto !important', maxHeight: '90vh' }}
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}>
        <div>
          <Typography
            sx={{ fontWeight: 700, fontSize: '1.1rem' }}
            className="text-center">
            Reject Question
          </Typography>
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
          <div className="flex justify-center">
            <div className="break-words text-xs font-medium m-1 my-2">
              <Typography sx={{ fontSize: '0.8rem' }}>
                Reject a question for being unresolvable, irrelevant, or a
                duplicate.
              </Typography>
            </div>
          </div>
          {!responseMessage && (
            <div>
              <div className="break-words font-medium">
                <Typography
                  sx={{ fontWeight: 400, py: 0.6 }}
                  color="primary.main">
                  Question:
                </Typography>
                <div className="border-2 rounded">
                  <SmallQuestionCard questionId={questionId} />
                </div>
              </div>

              <div className="flex justify-center w-full mx-1 my-4">
                <Button
                  sx={{
                    borderRadius: '0.5rem',
                    p: '0.125rem',
                    mx: 2,
                    overflow: 'hidden',
                    position: 'relative',
                    '&:hover': {
                      color: isResolvable
                        ? 'text.disabled'
                        : theme.palette.secondary.main
                    }
                  }}
                  onClick={() => setSelectedRejectionReason('Unresolvable')}
                  disabled={isResolvable}
                  className={`relative group ${
                    isResolvable
                      ? 'bg-gray-300'
                      : 'bg-gradient-to-br from-rose-500 to-red-500 group-hover:from-rose-500 group-hover:to-red-500'
                  } ${
                    selectedRejectionReason === 'Unresolvable'
                      ? 'ring-4 ring-rose-400'
                      : ''
                  }`}>
                  <div
                    style={{
                      transitionTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
                      transitionDuration: '75ms',
                      borderRadius: '0.375rem',
                      transitionProperty: 'all',
                      backgroundImage:
                        selectedRejectionReason === 'Unresolvable' &&
                        !isResolvable
                          ? 'linear-gradient(to bottom right, from-rose-500 to-red-500)'
                          : '',
                      backgroundColor: isResolvable
                        ? '#e0e0e0'
                        : selectedRejectionReason === 'Unresolvable'
                        ? ''
                        : theme.palette.background.paper
                    }}
                    className={`relative px-2 py-2 flex items-center rounded-md ${
                      isResolvable ? '' : 'group-hover:bg-opacity-0'
                    }`}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: isResolvable ? '#a0a0a0' : 'text.primary',
                        '&:hover': {
                          color: isResolvable
                            ? 'text.disabled'
                            : selectedRejectionReason === 'Unresolvable'
                            ? ''
                            : 'primary.main'
                        }
                      }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          mr: 1
                        }}
                        className="font-sans uppercase">
                        Unresolvable
                      </Typography>
                      <Block sx={{}} />
                    </Box>
                  </div>
                </Button>

                <Button
                  sx={{
                    borderRadius: '0.5rem',
                    p: '0.125rem',
                    mx: 2,
                    overflow: 'hidden',
                    position: 'relative',
                    '&:hover': {
                      color: isRelevant
                        ? 'text.disabled'
                        : theme.palette.secondary.main
                    }
                  }}
                  disabled={isRelevant}
                  onClick={() => setSelectedRejectionReason('Irrelevant')}
                  className={`relative group ${
                    isRelevant
                      ? 'bg-gray-300'
                      : 'bg-gradient-to-br from-yellow-500 to-orange-500 group-hover:from-yellow-500 group-hover:to-orange-500'
                  } ${
                    selectedRejectionReason === 'Irrelevant'
                      ? 'ring-4 ring-yellow-400'
                      : ''
                  }`}>
                  <div
                    style={{
                      transitionTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
                      transitionDuration: '75ms',
                      borderRadius: '0.375rem',
                      transitionProperty: 'all',
                      backgroundImage:
                        selectedRejectionReason === 'Irrelevant' && !isRelevant
                          ? 'linear-gradient(to bottom right, from-yellow-500 to-orange-500)'
                          : '',
                      backgroundColor: isRelevant
                        ? '#e0e0e0'
                        : selectedRejectionReason === 'Irrelevant'
                        ? ''
                        : theme.palette.background.paper
                    }}
                    className={`relative px-2 py-2 flex items-center rounded-md ${
                      isRelevant ? '' : 'group-hover:bg-opacity-0'
                    }`}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: isRelevant ? '#a0a0a0' : 'text.primary',
                        '&:hover': {
                          color: isRelevant
                            ? 'text.disabled'
                            : selectedRejectionReason === 'Irrelevant'
                            ? ''
                            : 'primary.main'
                        }
                      }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          mr: 1
                        }}
                        className="font-sans uppercase">
                        Irrelevant
                      </Typography>
                      <HighlightOff sx={{}} />
                    </Box>
                  </div>
                </Button>

                <Button
                  sx={{
                    borderRadius: '0.5rem',
                    p: '0.125rem',
                    mx: 2,
                    overflow: 'hidden',
                    position: 'relative',
                    '&:hover': {
                      color: theme.palette.secondary.main
                    }
                  }}
                  onClick={() => setSelectedRejectionReason('Duplicate')}
                  className={`relative group bg-gradient-to-br from-green-500 to-blue-500 group-hover:from-green-500 group-hover:to-blue-500 ${
                    selectedRejectionReason === 'Duplicate'
                      ? 'ring-4 ring-blue-400'
                      : ''
                  }`}>
                  <div
                    style={{
                      transitionTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
                      transitionDuration: '75ms',
                      borderRadius: '0.375rem',
                      transitionProperty: 'all',
                      backgroundImage:
                        selectedRejectionReason === 'Duplicate'
                          ? 'linear-gradient(to bottom right, from-green-500 to-blue-500)'
                          : '',
                      backgroundColor:
                        selectedRejectionReason === 'Duplicate'
                          ? ''
                          : theme.palette.background.paper
                    }}
                    className={`relative px-2 py-2 flex items-center rounded-md group-hover:bg-opacity-0`}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'text.primary',
                        '&:hover': {
                          color:
                            selectedRejectionReason === 'Duplicate'
                              ? ''
                              : 'primary.main'
                        }
                      }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          mr: 1
                        }}
                        className="font-sans uppercase">
                        Duplicate
                      </Typography>
                      <Report sx={{}} />
                    </Box>
                  </div>
                </Button>
              </div>

              <Collapse
                in={selectedRejectionReason !== ''}
                sx={{ mb: 1.8, mt: 3, textAlign: 'center' }}>
                {selectedRejectionReason && (
                  <TextField
                    sx={{ width: '100%' }}
                    label="Rejection Reason"
                    multiline
                    rows={4}
                    value={rejectionReason}
                    onChange={(e) => setRejectionReason(e.target.value)}
                  />
                )}
              </Collapse>

              <div className="flex justify-end mt-2">
                <div className="mx-2">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'gray',
                      ':hover': { backgroundColor: '#757575' }
                    }}
                    onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
                <div className="mx-2">
                  <Button variant="contained" onClick={rejectConfirm}>
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          )}
          {responseMessage && (
            <div className="my-2">
              {responseMessage && (
                <Typography color="success.main" className="text-center">
                  {responseMessage}
                </Typography>
              )}

              <div className="flex mt-4 justify-center">
                <Button
                  onClick={closeModal}
                  variant="contained"
                  className="w-4/12">
                  Close
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  ) : null;
}
