import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { AuthContext, checkTokenStatus } from '../../App';
import AddEvaluationModal from '../modals/AddEvaluationModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import ModerationModal from '../modals/ModerationModal';
import SetResolutionImpactModal from '../modals/SetResolutionImpactModal';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteQuestion,
  editStructuredQuestion,
  fetchOutcomes,
  selectCurrentUserForecastByQuestion,
  selectEvaluationByQuestionUser,
  selectForecastsByQuestion,
  selectOutcomeById,
  selectResolutionEvaluationByQuestionUser,
  updateQuestion
} from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';

import { SsidChartOutlined } from '@mui/icons-material';
import { MenuItem, TextField } from '@mui/material/';
import { parseISO } from 'date-fns';
import { BsPlusSlashMinus } from 'react-icons/bs';

import { FaAsterisk } from 'react-icons/fa';
import { FiCheck, FiDelete, FiEdit, FiStar } from 'react-icons/fi';
import { GoComment } from 'react-icons/go';
import { MdSpeed } from 'react-icons/md';
import { TbLayoutAlignMiddle } from 'react-icons/tb';
import CreateCommentCard from '../cards/CreateCommentCard';

import {
  Button,
  Card,
  Divider,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { ClipLoader } from 'react-spinners';
import RoleBadgeIcon from '../icons/RoleBadgeIcon';

export default function ClickableQuestionCard({
  question,
  isLoggedIn,
  isVotingDisabled,
  setIsVotingDisabled,
  detail
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn, userData } = useContext(AuthContext);
  const userId = userData.id;
  const navigate = useNavigate();
  const theme = useTheme();
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );

  const questionId = question.id;

  const maxQuestionLength = 2000;

  const usersData = useSelector((state) => state.users.entities);
  const [questionText, setQuestionText] = useState(null);
  const [outcomeAsQuestion, setOutcomeAsQuestion] = useState(null);
  const [eventText, setEventText] = useState(null);
  const [questionState, setQuestionState] = useState('');
  const stateValues = [
    'happen',
    'occur',
    'be present',
    'transpire',
    'materialise',
    'take place'
  ];
  const [updateQuestionRequestStatus, setUpdateQuestionRequestStatus] =
    useState('idle');
  const [deleteQuestionRequestStatus, setDeleteQuestionRequestStatus] =
    useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [showAddComment, setShowAddComment] = useState(false);
  const [showAddEvaluation, setShowAddEvaluation] = useState(false);
  const [showModerationModal, setShowModerationModal] = useState(false);
  const [editCard, setEditCard] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const evaluation = useSelector((state) =>
    selectEvaluationByQuestionUser(state, questionId, userId)
  );
  const outcome = useSelector((state) =>
    selectOutcomeById(state, question.outcome_id)
  );
  const outcomeStatus = useSelector(
    (_state) => _state.outcomes.outcomes.status
  );
  const [resolutionCriteria, setResolutionCriteria] = useState(null);
  const [source, setSource] = useState(null);
  const [relevanceReason, setRelevanceReason] = useState(null);
  const [resolutionDate, setResolutionDate] = useState(null);
  const [resolutionSubmitter, setResolutionSubmitter] = useState(null);
  const [showResolutionImpactModal, setShowResolutionImpactModal] =
    useState(false);
  const forecasts = useSelector((state) =>
    selectForecastsByQuestion(state, questionId)
  );
  const hasResolved = question.current_resolution === undefined ? false : true;
  var isStructured = false;
  if (question) {
    if (question.hasOwnProperty('event')) {
      isStructured = true;
    }
  }

  const currentUserForecast = useSelector((state) =>
    selectCurrentUserForecastByQuestion(state, {
      userId: userData.id,
      questionId: questionId
    })
  );

  const forecastOverruleSettings = useSelector((state) =>
    selectSettingByName(state, 'Forecast Submission Override')
  );

  useEffect(() => {
    // check if auth token is valid
    if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      setIsVotingDisabled(true);
      return <Navigate to={'/login'} />;
    } else {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn, setIsVotingDisabled]);

  useEffect(() => {
    let isMounted = true;
    setErrorMessage('');
    function fetchOutcomeData() {
      if (isMounted) {
        if (outcomeStatus === 'idle') {
          const token = localStorage.getItem('auth_token');
          dispatch(fetchOutcomes({ auth_token: token }));
        }
      }
    }
    fetchOutcomeData();
    return () => {
      isMounted = false;
    };
  }, [outcomeStatus, dispatch, outcome]);

  const openQuestionView = (e, id) => {
    if (e.ctrlKey) {
      window.open(`/questions/${id}`, '_blank');
    } else {
      navigate(`/questions/${id}`);
    }
  };

  const createComment = () => {
    setShowAddComment(true);
  };

  const changeQuestionText = (event) => {
    setQuestionText(event.target.value);
  };

  const changeOutcomeAsQuestion = (event) => {
    setOutcomeAsQuestion(event.target.value);
  };

  const changeEventText = (event) => {
    setEventText(event.target.value);
  };

  const clickAddEvaluation = () => {
    setShowAddEvaluation(true);
  };

  const replyClick = () => {
    createComment();
  };

  const impactEvaluations = useSelector((state) =>
    selectResolutionEvaluationByQuestionUser(state, questionId, userId)
  );

  const resolutionSettings = useSelector((state) =>
    selectSettingByName(state, 'Resolution Impact Decider')
  );

  useEffect(() => {
    if (question.question_text) {
      setQuestionText(question.question_text);
    }
  }, [question.question_text]);

  useEffect(() => {
    if (question.outcome_as_question) {
      setOutcomeAsQuestion(question.outcome_as_question);
    }
  }, [question.outcome_as_question]);

  useEffect(() => {
    if (question.event) {
      setEventText(question.event);
    }
  }, [question.event]);

  useEffect(() => {
    if (question.state) {
      setQuestionState(question.state);
    }
  }, [question.state]);

  useEffect(() => {
    if (question.resolution_criteria) {
      setResolutionCriteria(question.resolution_criteria);
    }
  }, [question.resolution_criteria]);

  useEffect(() => {
    if (question.source) {
      setSource(question.source);
    }
  }, [question.source]);

  useEffect(() => {
    if (question.relevance_reason) {
      setRelevanceReason(question.relevance_reason);
    }
  }, [question.relevance_reason]);

  useEffect(() => {
    if (question.resolution_date) {
      setResolutionDate(parseISO(question.resolution_date));
    }
  }, [question.resolution_date]);

  const canUpdateQuestion =
    [
      question.id,
      questionText ||
        resolutionCriteria ||
        source ||
        resolutionDate ||
        relevanceReason,
      checkTokenStatus()
    ].every(Boolean) && updateQuestionRequestStatus === 'idle';

  const canDeleteQuestion =
    [question.id, checkTokenStatus()].every(Boolean) &&
    deleteQuestionRequestStatus === 'idle';

  const deleteQuestionData = async () => {
    if (canDeleteQuestion) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setDeleteQuestionRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: question.id,
          auth_token: token
        };
        await dispatch(deleteQuestion(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              if (editCard) {
                setEditCard(!editCard);
              }
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to delete question: ${err.message}`);
      } finally {
        if (isMounted) {
          setDeleteQuestionRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Question could not be deleted.');
    }
  };

  const updateQuestionData = async () => {
    if (canUpdateQuestion) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setUpdateQuestionRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: question.id,
          auth_token: token
        };
        if (questionText) {
          payload.question_text = questionText;
        }
        if (outcomeAsQuestion) {
          payload.outcome_as_question = outcomeAsQuestion;
        }
        if (resolutionCriteria) {
          payload.resolution_criteria = resolutionCriteria;
        }
        if (source) {
          payload.source = source;
        }
        if (resolutionDate) {
          payload.resolution_date = resolutionDate;
        }
        if (relevanceReason) {
          payload.relevance_reason = relevanceReason;
        }
        if (
          question.status === 'Rejected' &&
          question.created_by.username === userData.username
        ) {
          payload.reset_pending = true;
        }
        if (eventText) {
          payload.event = eventText;
        }
        if (questionState) {
          payload.question_state = questionState;
        }

        if (isStructured) {
          await dispatch(editStructuredQuestion(payload))
            .unwrap()
            .then((response) => {
              if (isMounted) {
                setEditCard(!editCard);
              }
            });
        } else {
          await dispatch(updateQuestion(payload))
            .unwrap()
            .then((response) => {
              if (isMounted) {
                setEditCard(!editCard);
              }
            });
        }
      } catch (err) {
        setErrorMessage(`Failed to update question: ${err.message}`);
      } finally {
        if (isMounted) {
          setUpdateQuestionRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Question could not be updated.');
    }
  };

  const timeSincePosted = () => {
    return moment.utc(question.created_at).local().fromNow();
  };

  const isEdited = () => {
    return question.modified_at ? true : false;
  };

  const getStatusColour = () => {
    let status =
      question.status !== 'Completed' ? question.status : completedStatusCalc();
    switch (status) {
      case 'Pending':
        return theme.palette.statuses.mid1;
      case 'Rejected':
        return theme.palette.statuses.mid5;
      case 'Not Submitted':
        return theme.palette.statuses.mid2;
      case 'Accepted':
        return theme.palette.statuses.mid3;
      case 'Submitted':
        return theme.palette.statuses.mid3;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Duplicate':
        return theme.palette.statuses.mid6;
      default:
        return theme.palette.statuses.mid7;
    }
  };

  const getResolutionColour = () => {
    switch (question.current_resolution.name) {
      case 'True':
        return theme.palette.statuses.mid1;
      case 'False':
        return theme.palette.statuses.mid5;
      case 'Ambiguous':
        return theme.palette.statuses.mid4;
      case 'Not Set':
        return theme.palette.success.main;
      default:
        return null;
    }
  };

  let resolution_text = null;
  if (question.current_resolution != null) {
    resolution_text =
      'Resolved ' + moment(question.current_resolution.resolved_at).fromNow();
  }

  const completedStatusCalc = () => {
    if (outcome !== undefined) {
      if (outcome.statuses.includes('Forecasting')) {
        return 'Submitted';
      } else {
        return 'Closed';
      }
    } else {
      return 'Closed';
    }
  };

  const categoryTextColor = (category) => {
    switch (category) {
      case 'Very Low':
        return 'text-red-500';
      case 'Low':
        return 'text-orange-500';
      case 'Medium':
        return 'text-blue-500';
      case 'High':
        return 'text-teal-500';
      case 'Very High':
        return 'text-green-500';
      default:
        return 'bg-blue-500';
    }
  };

  const evaluationScoreCategoryTextColor = (category) => {
    switch (category) {
      case 'Furthest':
        return 'text-red-500';
      case 'Far':
        return 'text-orange-500';
      case 'Average':
        return 'text-blue-500';
      case 'Close':
        return 'text-teal-500';
      case 'Correct':
        return 'text-green-500';
      default:
        return 'bg-blue-500';
    }
  };

  let content;
  let canUserEvaluate =
    outcomeStatus === 'succeeded'
      ? !isVotingDisabled &&
        !evaluation &&
        outcome.statuses.includes('Evaluation') &&
        question.created_by.username !== userData.username
      : false;
  let canRenderCard =
    outcomeStatus === 'succeeded' &&
    question &&
    outcome &&
    (outcome.statuses !== 'Generation' ||
      question.created_by.username === userData.username ||
      userData.role === 'Moderator' ||
      userData.role === 'Admin' ||
      userData.role === 'Observer' ||
      outcome.created_by.username === userData.username);

  if (usernameSettings === undefined) {
    content = null;
  } else if (outcomeStatus === 'loading') {
    content = (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  } else if (canRenderCard) {
    content = (
      <div>
        <Card
          id={'questionCard' + questionId}
          sx={{
            transition: '0.3s',
            boxShadow: '0 7px 20px -9px rgba(0,0,0,0.3)',
            '&:hover': {
              boxShadow: '0 14px 37px -9.125px rgba(0,0,0,0.3)',
              bgcolor: 'hover'
            },
            borderLeftWidth: '5px',
            borderLeftColor: getStatusColour()
          }}
          className={`QuestionCard rounded shadow-lg mx-10 my-2 cursor-pointer duration-300`}
          onClick={(e) => openQuestionView(e, questionId)}>
          <div className="px-5 pt-3">
            <div className="break-words">
              <>
                <div className="flex items-start justify-between mb-1 max-h-42 overflow-y-auto">
                  {editCard ? (
                    isStructured ? (
                      <div>
                        <TextField
                          type="text"
                          variant="outlined"
                          value={eventText}
                          sx={{ m: 0.6 }}
                          inputProps={{ style: { fontSize: '0.875rem' } }}
                          onChange={(event) => changeEventText(event)}
                          className="w-full"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                        <TextField
                          label="State"
                          id="question-text-state"
                          select
                          variant="standard"
                          defaultValue="happen"
                          className="w-48"
                          sx={{ m: 0.6 }}
                          value={questionState}
                          onChange={(event) =>
                            setQuestionState(event.target.value)
                          }>
                          {stateValues.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    ) : (
                      <TextField
                        type="text"
                        variant="outlined"
                        value={questionText}
                        sx={{ mx: 0.6 }}
                        inputProps={{ style: { fontSize: '0.875rem' } }}
                        onChange={(event) => changeQuestionText(event)}
                        className="w-full"
                        helperText={`${questionText.length}/${maxQuestionLength}`}
                        error={questionText.length > maxQuestionLength}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    )
                  ) : (
                    <Typography sx={{ fontWeight: 600, fontSize: '1.1rem' }}>
                      <span className="whitespace-pre-line">
                        {question.question_text}
                      </span>
                    </Typography>
                  )}
                  <div className="flex items-center">
                    <div className="">
                      {forecasts !== undefined && forecasts.length > 0 && (
                        <div className="flex items-center mr-2">
                          <MdSpeed className="mr-1" size={22} />
                          <Typography sx={{ mr: 0.6 }}>
                            Current Forecast:
                          </Typography>
                          {forecasts !== undefined && forecasts.length > 0 && (
                            <Typography>
                              {Number.isInteger(
                                forecasts[forecasts.length - 1].probability *
                                  100
                              )
                                ? forecasts[forecasts.length - 1].probability *
                                  100
                                : (
                                    forecasts[forecasts.length - 1]
                                      .probability * 100
                                  ).toFixed(2)}
                              %
                            </Typography>
                          )}
                        </div>
                      )}
                    </div>
                    {question.outcome_as_question && (
                      <Typography
                        sx={{
                          borderColor: theme.palette.statuses.mid9,
                          backgroundColor: theme.palette.statuses.mid9,
                          py: 0,
                          px: 0.3,
                          fontSize: '0.85rem',
                          mx: 0.6
                        }}
                        className={`border-2 rounded text-white font-normal text-xs px-1 ml-1`}>
                        Outcome Question
                      </Typography>
                    )}
                    {hasResolved == null && (
                      <Typography
                        sx={{
                          borderColor: getStatusColour(),
                          backgroundColor: getStatusColour(),
                          py: 0,
                          px: 0.3,
                          fontSize: '0.9rem'
                        }}
                        className={`border-2 rounded text-white font-normal text-xs px-1 ml-1`}>
                        {question.status !== 'Completed'
                          ? question.status
                          : completedStatusCalc()}
                      </Typography>
                    )}
                    {hasResolved && (
                      <Typography
                        sx={{
                          borderColor: getResolutionColour(),
                          backgroundColor: getResolutionColour(),
                          py: 0,
                          px: 0.3,
                          fontSize: '0.85rem',
                          mx: 0.6,
                          textTransform: 'capitalize'
                        }}
                        className={`border-2 rounded text-white font-normal text-xs px-1 ml-1`}>
                        {question.current_resolution?.name.replace(
                          'Not Set',
                          'Reopened'
                        )}
                      </Typography>
                    )}
                  </div>
                </div>
              </>
              <Divider />
              <div className="QuestionInfo flex items-center justify-between ml-2">
                <div className="flex items-center">
                  <Typography
                    sx={{ fontSize: '0.95rem', mr: 0.6 }}
                    color="text.secondary">
                    Posted {timeSincePosted()}
                  </Typography>
                  {(userData.role === 'Admin' ||
                    userData.role === 'Moderator' ||
                    usernameSettings.active) && (
                    <div className="flex items-center">
                      <Typography
                        sx={{ mr: 0.6, fontSize: '0.95rem' }}
                        color="text.secondary">
                        by
                      </Typography>
                      <Typography
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/profile/${question.created_by.username}`);
                        }}
                        sx={{ fontSize: '0.95rem' }}
                        className="hover:underline hover:cursor-pointer"
                        color="primary.main">
                        {question.created_by.username}
                      </Typography>
                      <div className="pb-1">
                        <RoleBadgeIcon
                          role={usersData[question.created_by.id]?.role}
                        />
                      </div>
                    </div>
                  )}
                  {isEdited() && (
                    <div className="mr-1">
                      <Tooltip title="Edited">
                        <div>
                          <FaAsterisk className="py-1" />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {question.resolvable && (
                    <div className="mr-1">
                      <Tooltip title="Is Resolvable">
                        <div>
                          <FiCheck />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {question.relevant && (
                    <div className="mr-1">
                      <Tooltip title="Is Relevant" placement="bottom">
                        <div>
                          <FiCheck />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {question.forecast_override === 'True' &&
                    ((outcome.created_by.username === userData.username &&
                      forecastOverruleSettings.options.find(
                        (setting) => setting.name === 'outcome owner'
                      ).selected === true) ||
                      userData.role === 'Admin') && (
                      <div className="mr-1">
                        <Tooltip title="Forecast override" placement="bottom">
                          <div>
                            <FiCheck />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                </div>
                <div>
                  <Typography
                    sx={{ fontSize: '0.95rem', mr: 0.6 }}
                    color="text.secondary">
                    {resolution_text}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          {question.status === 'Duplicate' && (
            <div className="px-5">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  openQuestionView(e, question.parent_question_id);
                }}>
                Duplicate Question
              </Button>
            </div>
          )}

          {!detail && (
            <div className="flex pb-1 pt-1 items-center justify-between">
              <div className="ml-5 flex flex-wrap">
                {canUserEvaluate && (
                  <div className="flex items-center mr-2">
                    <Button
                      disabled={!isLoggedIn}
                      onClick={(e) => {
                        e.stopPropagation();
                        clickAddEvaluation();
                      }}>
                      <MdSpeed className="mr-1 mb-0.5" />
                      Add Evaluation
                    </Button>
                  </div>
                )}

                {question.question_score && (
                  <div className="flex items-center mr-2">
                    <FiStar className="mr-1 pb-0.5" fill="black" />
                    <Typography sx={{ mr: 0.6 }}>Score:</Typography>
                    <Typography
                      sx={{ fontWeight: 500 }}
                      className={`${categoryTextColor(
                        question.question_score_category.name
                      )}`}>
                      {question.question_score_category.name +
                        ' (' +
                        (question.question_score * 100).toFixed(1) +
                        ')'}
                    </Typography>
                  </div>
                )}

                {evaluation &&
                  evaluation.category &&
                  !outcome.statuses.includes('Forecasting') &&
                  !outcome.statuses.includes('Closed') && (
                    <div className="flex items-center mr-2">
                      <MdSpeed className="mr-1 mb-0.5" />
                      <Typography sx={{ mr: 0.6 }}>
                        Your Evaluation:{' '}
                      </Typography>
                      <Typography
                        className={`${categoryTextColor(
                          evaluation.category.name
                        )}`}>
                        {evaluation.category.name}
                      </Typography>
                    </div>
                  )}

                {evaluation &&
                  evaluation.evaluation_score !== null &&
                  evaluation.evaluation_score !== undefined && (
                    <div className="flex items-center mr-2">
                      <FiStar className="mr-1 pb-0.5" />
                      <Typography sx={{ mr: 0.6 }}>
                        Your {evaluation.evaluation_score_type} Evaluation
                        Score:{' '}
                      </Typography>
                      <Typography
                        className={`${evaluationScoreCategoryTextColor(
                          evaluation.evaluation_score_category.name
                        )}`}>
                        {evaluation.evaluation_score_category.name +
                          ' (' +
                          evaluation.evaluation_score +
                          ')'}
                      </Typography>
                    </div>
                  )}

                {question.average_evaluation !== undefined &&
                  question.average_evaluation !== 0 &&
                  question.average_evaluation_category &&
                  (evaluation ||
                    question.created_by.username === userData.username ||
                    outcome.created_by.username === userData.username ||
                    userData.role === 'Admin' ||
                    userData.role === 'Moderator') &&
                  !outcome.statuses.includes('Forecasting') &&
                  !outcome.statuses.includes('Closed') && (
                    <div className="flex items-center mr-2">
                      <TbLayoutAlignMiddle className="mr-1" />
                      <Typography sx={{ mr: 0.6 }}>
                        Community Evaluation:{' '}
                      </Typography>
                      <Typography
                        className={`${categoryTextColor(
                          question.average_evaluation_category.name
                        )}`}>
                        {question.average_evaluation_category.name +
                          ' (' +
                          Math.round(question.average_evaluation * 10) / 10 +
                          ')'}
                      </Typography>
                    </div>
                  )}
                {currentUserForecast &&
                  (currentUserForecast?.probability?.bucket ? (
                    <div className="flex items-center mr-2">
                      <FiStar className="mr-1 pb-0.5" fill="black" />
                      <Typography sx={{ mr: 0.6 }}>
                        Your Forecast:{' '}
                        {currentUserForecast.probability.bucket?.name} (
                        {(
                          currentUserForecast?.probability.display_probability *
                          100
                        ).toFixed(1)}
                        %)
                      </Typography>
                    </div>
                  ) : (
                    <div className="flex items-center mr-2">
                      <FiStar className="mr-1 pb-0.5" fill="black" />
                      <Typography sx={{ mr: 0.6 }}>
                        Your Forecast:{' '}
                        {(
                          currentUserForecast?.probability.display_probability *
                          100
                        ).toFixed(1)}
                        %
                      </Typography>
                    </div>
                  ))}
                <div className="flex items-center mr-2">
                  <Button
                    disabled={!isLoggedIn}
                    onClick={(e) => {
                      e.stopPropagation();
                      replyClick();
                    }}
                    size="small">
                    <GoComment className="mr-1 pl-0.5" />
                    <span>Add a comment</span>
                  </Button>
                </div>
                {impactEvaluations &&
                  impactEvaluations.resolution_impact !== undefined &&
                  resolutionSettings.options.find(
                    (setting) => setting.name === 'questioner'
                  ).selected === true &&
                  !outcome.statuses.includes('Forecasting') &&
                  !outcome.statuses.includes('Closed') && (
                    <div className="flex items-center mr-2">
                      <SsidChartOutlined sx={{ fontSize: '1.1rem', mr: 0.6 }} />
                      <Typography sx={{ mr: 0.6 }}>
                        Your Resolution Impact Evaluation:{' '}
                      </Typography>
                      <Typography
                        className={`${
                          impactEvaluations.resolution_impact
                            ? 'text-green-500'
                            : 'text-red-500'
                        }`}>
                        {impactEvaluations.resolution_impact
                          ? 'Positive'
                          : 'Negative'}
                      </Typography>
                    </div>
                  )}

                {!impactEvaluations &&
                  userData.role === 'Questioner' &&
                  question.created_by.username !== userData.username &&
                  resolutionSettings.options.find(
                    (setting) => setting.name === 'questioner'
                  ).selected === true &&
                  outcome.statuses.includes('Evaluation') && (
                    <div className="flex items-center mr-2">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setResolutionSubmitter('questioner');
                          setShowResolutionImpactModal(true);
                        }}>
                        <BsPlusSlashMinus className="mr-1 pl-0.5 pb-0.5" />
                        Add Resolution Impact Evaluation
                      </Button>
                    </div>
                  )}
                {question.evaluations.length === 0 &&
                  (outcome.statuses.includes('Generation') ||
                    outcome.statuses.includes('Moderation')) &&
                  !evaluation &&
                  (question.created_by.username === userData.username ||
                    userData.role === 'Admin' ||
                    userData.role === 'Moderator') && (
                    <div className="flex items-center mr-2">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();

                          setEditCard(!editCard);
                        }}
                        className={'inline-flex items-center'}
                        size="small">
                        <FiEdit className="mr-1 pb-0.5" />
                        {editCard ? 'Cancel' : 'Edit'}
                      </Button>
                    </div>
                  )}
                {(question.created_by.username === userData.username &&
                  (outcome.statuses.includes('Generation') ||
                    outcome.statuses.includes('Moderation'))) ||
                  (userData.role === 'Admin' && (
                    <div className="flex items-center mr-2">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteConfirm(true);
                        }}
                        className={'inline-flex items-center'}
                        size="small">
                        <FiDelete className="mr-1 pl-0.5 pb-0.5" />
                        Delete
                      </Button>
                    </div>
                  ))}

                {(outcome.statuses.includes('Generation') ||
                  outcome.statuses.includes('Moderation') ||
                  outcome.statuses.includes('Evaluation')) &&
                  (userData.role === 'Moderator' ||
                    userData.role === 'Admin') && (
                    <div className="flex items-center mr-2">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowModerationModal(true);
                        }}
                        className={'inline-flex items-center'}
                        size="small">
                        Moderate
                      </Button>
                    </div>
                  )}
                {editCard && (
                  <div className="flex justify-end px-4">
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        updateQuestionData();
                      }}>
                      {question.status === 'Rejected' &&
                      question.created_by.username === userData.username
                        ? 'Resubmit'
                        : 'Save'}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
          {showAddComment && (
            <CreateCommentCard
              close={() => {
                setShowAddComment(false);
              }}
              afterSubmit={() => {
                setShowAddComment(false);
              }}
              questionId={question.id}
            />
          )}

          {showResolutionImpactModal && (
            <SetResolutionImpactModal
              shown={showResolutionImpactModal}
              close={() => {
                setShowResolutionImpactModal(false);
              }}
              questionId={questionId}
              submitter={resolutionSubmitter}
            />
          )}

          {showAddEvaluation && (
            <AddEvaluationModal
              shown={showAddEvaluation}
              close={() => {
                setShowAddEvaluation(false);
              }}
              questionId={questionId}
              outcomeId={outcome.id}
            />
          )}

          {showModerationModal && (
            <ModerationModal
              shown={showModerationModal}
              close={() => {
                setShowModerationModal(false);
              }}
              questionId={questionId}
            />
          )}

          {showDeleteConfirm && (
            <ConfirmationModal
              shown={showDeleteConfirm}
              close={() => {
                setShowDeleteConfirm(false);
              }}
              confirm={() => {
                setShowDeleteConfirm(false);
                deleteQuestionData();
              }}
              confirmationMessage="Do you really want to delete this question? This process cannot be undone"
            />
          )}
        </Card>
      </div>
    );
  } else {
    content = null;
  }
  return <section>{content}</section>;
}
