import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserPreferences } from '../../store/slices/userSlice';
import FreeformQuestionForm from './FreeformQuestionForm';
import QuestionTypeToggle from './QuestionTypeToggle';
import StructuredQuestionForm from './StructuredQuestionForm/StructuredQuestionForm';

export default function CreateQuestion({ outcome }) {
  const [errorMessage, setErrorMessage] = useState('');

  const userPreferences = useSelector((state) => selectUserPreferences(state));

  return (
    <Accordion
      defaultExpanded={true}
      disableGutters
      sx={{
        px: userPreferences.outcome_layout === 'card' ? 0.9 : 3.6,
        pt: 1.0,
        pb: 1.0,
        mb: '5px !important'
      }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="create-question-content"
        id="create-question-header">
        <Typography sx={{ fontSize: '1.4rem', paddingLeft: '1rem' }}>
          Post a Question
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <form className="px-2">
          <div className="mt-0 flex flex-row justify-between items-center">
            <div>
              <Typography sx={{ fontSize: '1rem', mr: 1 }}>
                Post a Question to help inform the Outcome Owner's decision.
                Leading indicators that resolve on or close to the decision date
                will provide the most information.
              </Typography>
            </div>
            <div className="flex items-start">
              <Button
                variant="text"
                size="small"
                sx={{
                  ml: 1,
                  mx: 0.6,
                  maxWidth: '170px',
                  textAlign: 'center',
                  p: 0
                }}
                onClick={() => {
                  const url = `/faq?section=questioner#WhatMakesAGoodQuestion`;
                  window.open(url, '_blank');
                }}>
                What makes a good question? <OpenInNewIcon fontSize="small" />
              </Button>
              <QuestionTypeToggle />
            </div>
          </div>
          {userPreferences.question_type === 'freeform' ? (
            <FreeformQuestionForm
              outcome={outcome}
              setErrorMessage={setErrorMessage}
            />
          ) : (
            <StructuredQuestionForm
              outcome={outcome}
              setErrorMessage={setErrorMessage}
            />
          )}
          <Typography color="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        </form>
      </AccordionDetails>
    </Accordion>
  );
}
