import { configureStore } from '@reduxjs/toolkit';
import outcomeReducer from './slices/outcomeSlice';
import questionnaireReducer from './slices/questionnaireSlice';
import settingsReducer from './slices/settingsSlice';
import usersReducer from './slices/userSlice';
import userGroupReducer from './slices/userGroupSlice'
import bucketReducer from './slices/bucketSlice'

export default configureStore({
  reducer: {
    outcomes: outcomeReducer,
    settings: settingsReducer,
    users: usersReducer,
    questionnaires: questionnaireReducer,
    userGroups: userGroupReducer,
    buckets: bucketReducer
  }
});
