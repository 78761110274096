import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  fetchBucketPresets,
  deleteBucketPreset,
  selectAllPresets,
} from '../../store/slices/bucketSlice';
import BucketPresetForm from '../../components/modals/BucketPresetForm';

export default function BucketPresetModal({ shown, close }) {
  const dispatch = useDispatch();
  const bucketPresets = useSelector((state) => selectAllPresets(state));
  const presetStatus = useSelector((state) => state.buckets.presets.status); 
  const [filteredPresets, setFilteredPresets] = useState(bucketPresets);
  const [search, setSearch] = useState('');
  const [showPresetForm, setShowPresetForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [presetId, setPresetId] = useState();
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const displayedPresets = filteredPresets.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  useEffect(() => {
    if (presetStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchBucketPresets(token));
    }
  }, [presetStatus, dispatch]);

  useEffect(() => {
    const lowercasedFilter = search.toLowerCase();
    const filteredData = bucketPresets.filter((item) =>
      item.name.toLowerCase().includes(lowercasedFilter)
    );
    setFilteredPresets(filteredData);
  }, [search, bucketPresets]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handleDelete = (presetId) => {
    const token = localStorage.getItem('auth_token');
    dispatch(
      deleteBucketPreset({
        id: presetId,
        auth_token: token
      })
    );
  };

  const handleEdit = (id) => {
    setEditMode(true);
    setPresetId(id);
    setShowPresetForm(true);
  };
  
  const handleCreate = () => {
    setEditMode(false);
    setPresetId('');
    setShowPresetForm(true);
  }

  function Search() {
    return (
      <div>
        <div className="flex justify-between">
          <TextField
            label="Search Presets"
            value={search}
            onChange={handleSearchChange}
            style={{ marginTop: '-5px', width: '80%' }}
            size="small"
          />
          <Button
            onClick={handleCreate}
            style={{ fontSize: '12px' }}
          >
            Create
          </Button>
        </div>
        {displayedPresets.length > 0 ? (
          <List dense>
            {displayedPresets.map((preset) => (
              <Card key={preset.id} sx={{ padding: "10px", margin: "10px", backgroundColor: "background.default" }}>
                <ListItem
                  key={preset.id}
                  secondaryAction={
                    <>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => handleEdit(preset.id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDelete(preset.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  }
                >
                  <ListItemText primary={preset.name} />
                </ListItem>
              </Card>
            ))}
          </List>
        ) : (
          <Typography>No presets found. <Button onClick={handleCreate}>Create a new preset</Button></Typography>
        )}
      </div>
    );
  }

  function PageControls() {
    return (
      <div className="flex justify-between">
        <Button onClick={() => setPage(page - 1)} disabled={page === 1}>
          Previous
        </Button>
        <Typography>
          {page} of {Math.ceil(filteredPresets.length / itemsPerPage)}
        </Typography>
        <Button
          onClick={() => setPage(page + 1)}
          disabled={page === Math.ceil(filteredPresets.length / itemsPerPage)}
        >
          Next
        </Button>
      </div>
    );
  }

  return shown ? (
    <div className="b-2 modal-backdrop" onClick={close}>
      {showPresetForm && (
        <BucketPresetForm shown={showPresetForm} close={() => setShowPresetForm(false)} isEditMode={editMode} id={presetId} />
      )}
      <Card
        className="modal-content b-2 w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
        onClick={(e) => e.stopPropagation()}
        sx={{boxShadow: 3}}
      >
        <div className="modal-header flex justify-between mb-4">
          <Typography variant="h6" className="modal-title">
            Manage Bucket Presets
          </Typography>
          <Button
            onClick={close}
            className="modal-close"
            variant="contained"
            color="error"
          >
            Close
          </Button>
        </div>
        <Search />
        <PageControls />
      </Card>
    </div>
  ) : null;
}
