
import { useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { AuthContext, checkTokenStatus } from "../../App";
import { Card, IconButton, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import BucketForm from "../modals/BucketForm";
import { fetchBucketConfigurations, addNewBucket, updateBucket, deleteBucket, fetchBuckets } from "../../store/slices/bucketSlice";



export default function BucketList({ bucketConfiguration, isNewConfiguration, handleAddNewBucket_new, handleUpdateBucket_new, handleDeleteBucket_new }) {
    const { setIsLoggedIn } = useContext(AuthContext);

    const [showNewBucketForm, setShowNewBucketForm] = useState(false);
    const [showEditBucketForm, setShowEditBucketForm] = useState(false);
    const [selectedBucket, setSelectedBucket] = useState(null);
    const [selectedConfiguration, setSelectedConfiguration] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();

    const bucketConfigurations = bucketConfiguration?.bucketConfigurations || [];

    const closeForms = () => {
        setShowNewBucketForm(false);
        setShowEditBucketForm(false);
    };

    useEffect(() => {
        setErrorMessage("")
    }, [showNewBucketForm, showEditBucketForm]);

    const fetchAuthToken = async () => {
        const token = localStorage.getItem("auth_token");
        const status = await checkTokenStatus(token);
        if (status === 401) {
            setIsLoggedIn(false);
            return <Navigate to="/login" />;
        }
        return token;
    }

    const invoke = async (functionToCall, payload) => {
        setLoading(true);
        setIsMounted(true);
        setErrorMessage("");
        await fetchAuthToken().then(async (token) => {
            try {
                await dispatch(functionToCall({ ...payload}))
                .unwrap()
                .then((response) => {
                    closeForms();
                    return response;
                });
                
            } catch (error) {
                setErrorMessage(String(error.message).replace("1", "100"));
                return error;
            } finally {
                dispatch(fetchBucketConfigurations(token));
                dispatch(fetchBuckets(token));
            }
        });
        setLoading(false);
        setIsMounted(false);
    };

    const handleAddNewBucket = (newBucket) => {
        if (newBucket.name === "" || newBucket.upper_limit === null || newBucket.lower_limit === null || newBucket.configuration_id === null) {
            setErrorMessage("Please fill out all fields");
            return;
        }
        if (newBucket.upper_limit === "") {
            newBucket.upper_limit = 0;
        }
        if (newBucket.lower_limit === "") {
            newBucket.lower_limit = 0;
        }
        fetchAuthToken().then((token) => {
            const response = invoke(addNewBucket, { name: newBucket.name, upperLimit: newBucket.upper_limit, lowerLimit: newBucket.lower_limit, bucketConfigurationId: newBucket.configuration_id, auth_token: token });
            if (response.message === "Bucket added successfully") {
                setShowNewBucketForm(false);
            } else {
                return;
            }
        });
    };

    const handleUpdateBucket = (bucket) => {
        if (bucket === null) {
            setErrorMessage("Please select a bucket");
            return;
        }
        if (bucket.name === "" || bucket.upper_limit === null || bucket.lower_limit === null) {
            setErrorMessage("Please fill out all fields");
            return;
        }
        const changes = getChanges(selectedBucket, bucket);
        fetchAuthToken().then(async (token) => {
            const response = invoke(updateBucket, { id: bucket.id, name: changes.name, upperLimit: changes.upper_limit, lowerLimit: changes.lower_limit, bucketConfigurationId: changes.configuration_id, auth_token: token });
            if (response.message === "Bucket updated successfully") {
                setShowEditBucketForm(false);
            }
        });
    };

    const getChanges = (old, newObj) => {
        const changes = {};
        for (const key in newObj) {
            if (old[key] != newObj[key]) {
                changes[key] = newObj[key];
            }
        }
        return changes;
    };

    const handleDeleteBucket = (bucket) => {
        fetchAuthToken().then((token) => {
            invoke(deleteBucket, { id: bucket.id, auth_token: token });
            if (errorMessage === "") {
                setShowEditBucketForm(false);
            } else {
                return;
            }
        });
    };

    const fillBucketGapsWithDummies = (raw_buckets) => {
        const buckets = [...raw_buckets].sort((a, b) => a.lower_limit - b.lower_limit);
        const allBuckets = [];
        for (let i = 0; i < buckets.length; i++) {
            allBuckets.push(buckets[i]);
            if (i < buckets.length - 1 && buckets[i].upper_limit !== buckets[i + 1].lower_limit) {
                allBuckets.push({
                    lower_limit: buckets[i].upper_limit,
                    upper_limit: buckets[i + 1].lower_limit,
                    dummy: true
                });
            }
        }
        if (buckets.length === 0) {
            allBuckets.push({
                lower_limit: 0,
                upper_limit: 1,
                dummy: true
            });
        } else if (buckets[buckets.length - 1].upper_limit !== 1) {
            allBuckets.push({
                lower_limit: buckets[buckets.length - 1].upper_limit,
                upper_limit: 1,
                dummy: true
            });
        } else if (buckets[0].lower_limit !== 0) {
            allBuckets.unshift({
                lower_limit: 0,
                upper_limit: buckets[0].lower_limit,
                dummy: true
            });
        }
        return allBuckets;
    };
    let allBuckets = fillBucketGapsWithDummies(bucketConfiguration?.buckets || []);

    useEffect(() => {
        allBuckets = fillBucketGapsWithDummies(bucketConfiguration?.buckets || []);
    }, [bucketConfiguration]);

    return (
        <Card className="full-width p-4">

            {
                showNewBucketForm && (
                    <BucketForm
                        bucket={{ name: "", upper_limit: "", lower_limit: "", configuration_id: bucketConfiguration.id }}
                        onSubmit={ isNewConfiguration ? (newBucket) => { handleAddNewBucket_new(newBucket); setShowNewBucketForm(false); } : handleAddNewBucket }
                        errorMessage={errorMessage}
                        setShowBucketForm={setShowNewBucketForm}
                        bucketConfigurations={[]}
                        selectedConfiguration={bucketConfiguration}
                    />
                )
            }
            {showEditBucketForm && (
                <BucketForm 
                    bucket={selectedBucket} 
                    onSubmit={isNewConfiguration ? (newBucket) => { handleUpdateBucket_new(newBucket); setShowEditBucketForm(false); } : handleUpdateBucket }
                    errorMessage={errorMessage} 
                    setShowBucketForm={setShowEditBucketForm} 
                    bucketConfigurations={bucketConfigurations} 
                    selectedConfiguration={selectedConfiguration} 
                    handleDelete={isNewConfiguration ? handleDeleteBucket_new : handleDeleteBucket}
                />
            )}

            <Typography>
                Buckets
            </Typography>
            <Typography variant="caption">
                To edit a bucket, click on the bucket and fill in the details. {bucketConfiguration.is_valid !== "True" ? "To add a new bucket, click on the Add button." : ""}
            </Typography>

            <div className="flex mt-2">
                {allBuckets.map((bucket, index) => (
                    bucket.dummy ? (
                    <div key={index} style={{ width: `${(bucket.upper_limit - bucket.lower_limit) * 100}%`, justifyContent: "center", display: "flex", alignItems: "center", marginBottom: "10px" }}>
                        <IconButton
                            size="small"
                            style={{ padding: "3px" }}
                            onClick={() => {
                                setShowNewBucketForm(true);
                            }}
                        >
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Typography variant="caption">
                                    Add
                                </Typography>
                                <Add 
                                />
                            </div>
                        </IconButton>
                    </div>
                    ) : (
                        <Tooltip title={`${bucket.name}: ${(bucket.lower_limit*100).toFixed(2)}% - ${(bucket.upper_limit*100).toFixed(2)}%`} arrow key={index}>
                            <Card
                                key={index}
                                style={{
                                width: `${(bucket.upper_limit - bucket.lower_limit) * 100}%`,
                                position: "relative",
                                marginBottom: "10px",
                                border: "1px solid gray",
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                }}
                                onClick={() => {
                                    setSelectedBucket(bucket);
                                    setShowEditBucketForm(true);
                                }}
                            >

                                <div style={{ display: "flex", justifyContent: "center", padding: "4px", textAlign: "center", overflow: "hidden" }}>
                                    <Typography variant="caption">{bucket.name}</Typography>
                                </div>
                            </Card>
                        </Tooltip>
                    )
                ))}
            </div>
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                {[...Array(11)].map((_, index) => (
                    <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: index === 0 ? "flex-start" : index === 10 ? "flex-end" : "center", width: "1px" }}>
                        <div style={{ width: "1px", height: "10px", backgroundColor: "black"}} />
                        <Typography variant="caption" style={{ transform: index === 0 ? "translateX(-50%)" : index === 10 ? "translateX(50%)" : "translateX(0%)" }}>
                            {index * 10}%
                        </Typography>
                    </div>
                ))}
            </div>
        </Card>
    );
}